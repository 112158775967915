import jwt_decode from 'jwt-decode';

import { _getAccessToken } from "../services";
export const isValidToken = async ({ token,history, signout,setToken,userData }) => {
    const accessToken = token;
    // if (!accessToken) {
    //     logout(signout);
    // }
    if (accessToken) {
        var splitTokenAccess = jwt_decode(accessToken.split('Bearer ')[1]);
        let currentDate = new Date();
        if (splitTokenAccess.exp * 1000 < currentDate.getTime()) {
            // logout(signout);
            loginAgain(userData?.userId,setToken);
        }
    }
}

const logout = (signout) => {
    localStorage.setItem("message", "Your session has expired. Please login again.");
    localStorage.removeItem("token");
    signout();
}

const loginAgain = async (email,setToken) => {
    const token = await _getAccessToken(email?.toLowerCase());
    setToken(token.data?.data?.access_token);
    localStorage.setItem('token', token.data?.data?.access_token);
}