import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _sentChat = async (circle,userId,gameId,comment) => {
    try {
        const requestURL = serverURL + "comments"
        let body = _({
            circle,
            userId,
            gameId,
            comment
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();

        let response = await axios.post(requestURL, body)
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _sentContestChat = async (circle,userId,contestId,comment) => {
    try {
        const requestURL = serverURL + "comments"
        let body = _({
            circle,
            userId,
            contestId,
            comment
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();

        let response = await axios.post(requestURL, body)
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getcontestComments = async (contestId) => {
    try {
        const requestURL = serverURL + "comments"
        let params = _({
            contestId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.get(requestURL, { params })
        
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}