import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _getLeagueStats = async (filters, userId, betStatus) => {
    try {
        const headers = { Authorization: localStorage.getItem("token") }

        const requestURL = serverURL + "edge/advstats"
        let body = _({
            userId,
            league: _.get(filters, "league", null),
            betStatus: betStatus?.length ? betStatus : ["T", "B"],
            conference: _.get(filters, "conference", null),
            season: filters?.season ? [filters.season] : null,
            betType: filters.bet ? [filters.bet] : null, //[betType.filter(abb => abb[1] == filters.bet)?.[0]?.[0]] : null,
            gmSeg: filters.gmSeg ? [filters.gmSeg] : null // [GameSegAbb.filter(abb => abb[1] == filters.gmSeg)?.[0]?.[0]] : null,
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.put(requestURL, body,{headers});
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}
export const _getLeagueAdvanceStats = async (filters, userId, betStatus,section) => {
    try {
        const requestURL = serverURL + "edge/advstats2"
        let body = _({
            userId,
            section,
            league: _.get(filters, "league", null),
            betStatus: betStatus?.length ? betStatus : ["T", "B"],
            conference: _.get(filters, "conference", null),
            season: filters?.season ? [filters.season] : null,
            betType: filters.bet ? [filters.bet] : null, //[betType.filter(abb => abb[1] == filters.bet)?.[0]?.[0]] : null,
            gmSeg: filters.gmSeg ? [filters.gmSeg] : null // [GameSegAbb.filter(abb => abb[1] == filters.gmSeg)?.[0]?.[0]] : null,
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.put(requestURL, body);
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}

export const _getLeagueConfStats = async (filters, userId, betStatus) => {
    try {
        const requestURL = serverURL + "edge/confstats"
        let body = _({
            userId,
            league: _.get(filters, "league", null),
            betStatus: betStatus?.length ? betStatus : ["T", "B"],
            conference: _.get(filters, "conference", null),
            season: filters?.season ? [filters.season] : null,
            betType: filters.bet ? [filters.bet] : null, 
            gmSeg: filters.gmSeg ? [filters.gmSeg] : null 
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.put(requestURL, body);
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}