import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _sendNotification = async (user) => {
    try {
        const requestURL = serverURL + "notifications"
        let body = _({
            user,
            "messageId": "Invite"
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.post(requestURL, body);
        response = response.data;
        return response;
    } catch (error) {
        return {success:false}
    }
}

export const _markAsRead = async (id) => {
    const requestURL = serverURL + "notifications"
    let body = _({
        id,
        read: true
    }).value();
    let response = await axios.put(requestURL, body);
    if (response.data)
        return { success: true, data: response.data };
    else
        return { success: false };
}

export const _getNotification = async (user) => {
    try {
        const requestURL = serverURL + "notifications/next"
        let params = _({
            user
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    
        let response = await axios.get(requestURL, { params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
        
    } catch (error) {
        return { success: false };
    }
}

export const _getBetSyncNotification = async ()=>{
    
    try {
        const requestURL = serverURL + "messages?name=BetSyncIntro"
        let response = await axios.get(requestURL);
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
        
    } catch (error) {
        return { success: false };
    }
}

export const _getNewUserNotification = async () => {
    try {
        const requestURL = serverURL + "notifications/next"
        let params = _({
            user:"newuser"
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    
        let response = await axios.get(requestURL, { params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
        
    } catch (error) {
        return { success: false };
    }
}