import { serverURL } from "../constants";
import _ from 'lodash';
import localForage from "localforage";
const axios = require("axios");

export const _getSportsBook = async () => {
    
    try {
        const userId = (await localForage.getItem("email")) || null;
        const requestURL = serverURL + "sportsbooks"
        let params = _({
            // active: "true"
            userId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, {params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}
export const _getSingleSportsBook = async (sbookId) => {
    
    try {
        const requestURL = serverURL + "sportsbooks"
        let params = {
            sbookId
        };
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}
export const _myBets = async (user) => {
    try {
        const headers = { Authorization: localStorage.getItem("token") }
        const requestURL = serverURL + "auth/sharpsports/accts"
        let params = _({
            user
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params, headers });
        response = response.data;
        return response;
    } catch (error) {
        return null
    }

}

export const _mySportBookCount = async (user) => {
    const requestURL = serverURL + "sharpsports"
    let params = _({
        "BettorTakes.userId": user,
        "BettorTakes.btStage": "Confirm"
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.get(requestURL, { params });
    if (response?.data?.length)
        return { success: true, data: [] };
    else
        return { success: false};
}

export const _sportBookAccess = async (bettorAcctId, access) => {
    try {
        const requestURL = serverURL + `sharpsports/access`;
        let body = _({
            bettorAcctId,
            access: access
        }).omitBy(_.isUndefined).value();
        var response = await axios.put(requestURL, body);
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (error) {
        return { success: false };
    }
}

export const _mergeInHistory = async (body,token) => {
    const headers = { Authorization: token }
    try {
        const requestURL = serverURL + "auth/syncbets/confirm";
        const chunkSize = 50;
        let response;
        for (let i = 0; i < body?.length; i += chunkSize) {
            const chunk = body.slice(i, i + chunkSize);
            // do whatever
            try {
                response = await axios.put(requestURL, chunk, { headers });
            } catch (error) { }
            console.log(response);
        }

        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (error) {
        return { success: false };
    }
}

export const _betSyncNextStepAction = async (userId,token)=>{
    const headers = { Authorization: token }
    let params = _({
        userId
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    const requestURL = serverURL + "auth/syncbets/nextstep"
    var response = await axios.get(requestURL, { headers,params });
    if (response?.data?.length)
        return { success: true, data: response?.data };
    else
        return { success: false};
}

export const _getSyncBets = async (userId,stage,token)=>{
    const headers = { Authorization: token }
    let params = _({
        userId,
        stage
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    const requestURL = serverURL + "auth/syncbets"
    var response = await axios.get(requestURL, { headers,params });
    if (response?.data?.length)
        return { success: true, data: response?.data };
    else
        return { success: false};
}

export const _getSyncReconcileBets = async (userId,token)=>{
    const headers = { Authorization: token }
    let params = _({
        userId
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    const requestURL = serverURL + "auth/syncbets/reconcile"
    var response = await axios.get(requestURL, { headers,params });
    if (response?.data?.length)
        return { success: true, data: response?.data };
    else
        return { success: false};
}