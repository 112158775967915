exports.formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

exports.getMonthInEng = (date) => {
  const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' })
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    // return month +" "+ day+", "+year;
    return month +" "+ day;

}

exports.getMonthYearInEng = (date) => {
  const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' })
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    return month +" "+ day+", "+year;

}

exports.getStringDate = (date) => {
  const today = new Date().getDate();
  const comingDate = date.getDate();
  if (today == comingDate) return "Today";
  else if (today + 1 == comingDate) return "Tomorrow";
  else
    return (
      date.toLocaleString("default", { month: "short" }) +
      " " +
      date.getDate()
    );
}

// MM/DD/YYYY
exports.getFormattedDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return month + "/" + day + "/" + year;
}