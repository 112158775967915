import React, { useState, useEffect } from "react";
import { GameTile } from "./gameTile";
import close from "../../../img/cancelG.png"
import info from "../../../img/info.png"
import estar from "../../../img/estar.png"
import fstar from "../../../img/fStar.png"
import FormControl from '@mui/material/FormControl';
import { _getSportBookodds, _getSingleBetType, _getSingleSportsBook, _addLogs, _getOnBoardingSportBookodds } from "../../../services";
import _ from "lodash";
import { useStore } from "../../../store";
import { shallow } from "zustand/shallow"
import { PoppingText } from "../helper"
import { ON_BOARDING } from "../../../constants";


export default function SportBookMenu({ setOpenSportBook, sportBookGame, sportBookSection, sportBooks, sportBookBet, changeBetSportBook, betIndex, betType, showConfidenceInfo, setSportBookSignup, betSelection, game, ...props }) {

    const { userData } = useStore(state => ({
        userData: state?.userData,
    }), shallow)

    const [sportsBooksTemplate, setSportsTemplateBook] = useState([])
    const [pointSpread, setPointSpread] = useState({ sign: "", point: "" });
    const [moneyline, setMoneyLine] = useState({ sign: "", point: "" });
    const [addOdds, setAddOdds] = useState(false);
    const [confScale, setConfScale] = useState(1);
    const [signupOfferURL, setSignUpOfferURL] = useState("");
    const [loader, setLoader] = useState(true)
    const [currentBetType, setCurrentBetType] = useState();
    const [onBoardingOdds, setOnBoardingOdds] = useState([])
    useEffect(async () => {

        const sportsBooksData = [];
        let betStamp = "";
        let betOptionId = sportBookBet?.[betIndex]?.betoptionsId || sportBookBet?.[betIndex]?.betOptionId
        let sportBookId = sportBookBet?.[betIndex]?.sbookId;
        if (props?.showOnboardingNotifications) {
            await getOnBordingOdds(sportBookGame.gameId, betOptionId, sportBookId)
        }
        let betOdds = await _getSportBookodds(sportBookGame.gameId, betOptionId, betSelection);
        if (betOdds?.odds?.length) {
            sportBookBet[betIndex].sBook = betOdds?.odds
            sportBookBet[betIndex].betType = betOdds?.betoption?.betType;
            betType = betOdds?.betoption?.betType;
            setCurrentBetType(betType ?? betOdds?.betoption?.betType)
            sportBookBet?.[betIndex]?.sBook?.map(sBook => {
                let template = { ...sBook, betLine: sBook?.betline, moneyLine: sBook?.moneyline }
                if (template.betLine || template.moneyLine) {
                    template.sbookLink = _({
                        ios: template?.deep_link_url?.urls?.ios?.url ?? template?.deep_link_url?.mobile ?? null,
                        android: template?.deep_link_url?.urls?.android?.url ?? template?.deep_link_url?.mobile ?? null,
                        desktop: template?.deep_link_url?.urls?.desktop?.url ?? template?.deep_link_url?.desktop ?? null,
                    }).omitBy(_.isNull).omitBy(_.isUndefined).value();
                    sportsBooksData.push({
                        template,
                        book: template
                    })
                    if (betStamp == "" && template?.betStamp) {
                        let betStampArr = template?.betStamp?.split("/")
                        betStamp = template?.betStamp?.replace(betStampArr?.at(-1), "")
                        setSignUpOfferURL(betStamp)
                    }
                }
            })

        }
        if (sportsBooksData?.length == 0) {
            _addLogs(userData?.userId, { gameId: game?.gameId, betoptionsId: sportBookBet?.[betIndex]?.betoptionsId })
            let bet = (await _getSingleBetType(sportBookBet?.[betIndex]?.betoptionsId))?.data?.[0];
            if (bet?.betType) {
                let sportbook = (await _getSingleSportsBook(sportBookBet?.[betIndex]?.sbookId))?.data?.[0];
                if (sportbook?.logo) {
                    setCurrentBetType(bet?.betType);
                    sportBookBet[betIndex].betType = bet?.betType;
                    let sportBet = { ...sportbook, ...bet, ...sportBookBet?.[betIndex] }
                    sportBet.moneyLine = sportBet?.moneyline
                    sportBet.betLine = sportBet?.betline
                    sportsBooksData.push({
                        template: sportBet,
                        book: sportBet
                    })
                }

            }
        }
        setSportsTemplateBook(sportsBooksData);
        // setLoader(false)

        return () => {
            props?.setShowOnboardingNotifications(ON_BOARDING[4])
        };
    }, [])

    const getOnBordingOdds = async (gameId, betoptionsId, sbookId) => {
        const odds = await _getOnBoardingSportBookodds(gameId, betoptionsId, sbookId)
        let onBoardOdds = []
        odds?.map((odd, index) => {
            if (index < 4)
                onBoardOdds.push({
                    ...odd, book: {
                        betLine: odd?.betline,
                        moneyLine: odd?.moneyline,
                        sbookId
                    }
                })
        }
        )
        console.log({ onBoardOdds });
        setOnBoardingOdds(onBoardOdds?.length ? onBoardOdds : [])
    }

    const createBet = (e) => {
        e.preventDefault();
        let moneyLine = moneyline.sign == "-" ? moneyline.point * -1 : moneyline.point
        let betline = pointSpread.sign == "-" ? pointSpread.point * -1 : pointSpread.point
        if (currentBetType != "ML") {
            if (betline) {
                const sportBook = { moneyLine, betLine: betline ?? null, sbookId: 0 }
                changeBetSportBook({}, sportBook, confScale)
            }
        }
        else {
            const sportBook = { moneyLine, betLine: null, sbookId: 0 }
            changeBetSportBook({}, sportBook, confScale)
        }


    }

    const selectBet = async (e, sportBook) => {
        e.preventDefault();
        changeBetSportBook(sportBook.template, sportBook.book, confScale)
    }

    const setConfidenceLeve = (e, number) => {
        e.preventDefault();
        if (props?.showOnboardingNotifications == ON_BOARDING[6])
            return
        setConfScale(number);
        if (ON_BOARDING.slice(0,7)?.includes(props?.showOnboardingNotifications))
            if (onBoardingOdds?.length)
                props?.setShowOnboardingNotifications(ON_BOARDING[6])
            else
                props.setShowOnboardingNotifications(ON_BOARDING[4])
    }
    // const setConfidenceLevel = (e, level) => {
    //     e.preventDefault();
    //     const settings = userData;
    //     settings.confScale = level;
    //     localStorage.setItem("userData", JSON.stringify(settings))
    //     setUserData({ ...userData })
    // }
    return (
        <>
            {/* heading and close button */}
            <div className="container" style={{ marginTop: "17px", display: "flex", justifyContent: "space-between" }}>
                <div style={{ color: "black" }}>h</div>
                <span className="sportHeadline">
                    {addOdds ? "ENTER YOUR OWN ODDS" : ON_BOARDING?.includes(props?.showOnboardingNotifications) ? "SELECT YOUR ODDS" : "SPORTSBOOKS & ODDS"} </span>
                {ON_BOARDING?.slice(5,7)?.includes(props?.showOnboardingNotifications) ?
                    <div style={{ color: "black" }}>h</div>
                    : <img src={close} style={{ width: "18px", height: "18px", marginTop: "3px" }} onClick={() => {
                        if (addOdds)
                            setAddOdds(false)
                        else
                            setOpenSportBook(false)
                    }} />}
            </div>
            {props?.showOnboardingNotifications == ON_BOARDING[5] && <div className="onBoarding seques5Header">
            </div>}
            {props?.showOnboardingNotifications == ON_BOARDING[6] && <div className="onBoarding seques6Header">
            </div>}

            {/* game tile */}
            <div style={{ color: "white", marginTop: "2.3rem" }}>
                <GameTile show={true} {...sportBookGame} sportBookGame={true} />
                <div style={{ marginTop: "15px" }} className="text fontGray fs-12 text-capital text-center">
                    {sportBookSection}
                </div>
            </div>
            <div className="df" style={{ color: "white", justifyContent: "center", alignItems: "center", marginTop: "11px" }}>
                <img src={info} onClick={(e) => showConfidenceInfo(e, true)} style={{ width: "19px", height: "19px", marginRight: "7px" }} />
                <span className="fs-14">CONFIDENCE LEVEL / UNITS</span>
            </div>
            {props?.showOnboardingNotifications == ON_BOARDING[6] && <div className="" style={{ zIndex: 111, position: "fixed" }}>
                <div className="container" style={{ marginTop: "15px" }}>
                    <span className="text-center fs-20">
                        <PoppingText text={"Find and select the odds that closely match the current options."} />
                    </span>
                </div>
            </div>}
            <div className="container df" style={{ justifyContent: "space-around", alignItems: "center", margin: "16px 0" }}>
                <img src={confScale >= 1 ? fstar : estar} onClick={(e) => setConfidenceLeve(e, 1)} style={{ width: "60px", height: "60px" }} />
                <img src={confScale >= 2 ? fstar : estar} onClick={(e) => setConfidenceLeve(e, 2)} style={{ width: "60px", height: "60px" }} />
                <img src={confScale >= 3 ? fstar : estar} onClick={(e) => setConfidenceLeve(e, 3)} style={{ width: "60px", height: "60px" }} />
                <img src={confScale >= 4 ? fstar : estar} onClick={(e) => setConfidenceLeve(e, 4)} style={{ width: "60px", height: "60px" }} />
                <img src={confScale >= 5 ? fstar : estar} onClick={(e) => setConfidenceLeve(e, 5)} style={{ width: "60px", height: "60px" }} />
            </div>
            {props?.showOnboardingNotifications == ON_BOARDING[5] && <div className="onBoarding seques5lower">
            </div>}
            {props?.showOnboardingNotifications == ON_BOARDING[6] && <div className="onBoarding seques6lower" style={{ top: onBoardingOdds?.length == 1 ? "24rem" : onBoardingOdds?.length == 2 ? "29.8rem" : onBoardingOdds?.length == 3 ? "35rem" : "40.5rem" }}>
            </div>}
            {props?.showOnboardingNotifications == ON_BOARDING[5] && <div className="" style={{ zIndex: 111, position: "fixed" }}>
                <div className="container" style={{ marginTop: "15px" }}>
                    <span className="text-center fs-20">
                        <PoppingText text={"Please indicate your confidence level. if you don't select, the confidence level default to 1."} />
                    </span>
                </div>
            </div>}

            {/* sportsbooks */}
            {!addOdds && localStorage.getItem("onBoardingComplete") && <div className="container" style={{ marginTop: "20px" }}>
                {sportsBooksTemplate.map((bookTemplate, key) =>
                    <div className="df m-b-08" key={key} >
                        <div className={"bet p-0 df " + (bookTemplate?.template?.betStamp ? "col-7 dfRight" : "col-12")} onClick={(e) => selectBet(e, bookTemplate)}>
                            <div className={`col-9 p-l-0 df fdc p-r-0 text-center p-t-06 leftBox`} >
                                {["PS", "OU", "ML"]?.includes(currentBetType) ? <span>
                                    {sportBookBet[betIndex]?.betType == "PS" && <div className="bold">{bookTemplate?.book?.betLine > 0 ? "+" + bookTemplate?.book?.betLine : bookTemplate?.book?.betLine}</div>}
                                    {sportBookBet[betIndex]?.betType == "OU" && <div className="bold">
                                        {betIndex == 0 && <>{"U"}</>}
                                        {betIndex == 1 && <>{sportBookBet[betIndex]?.betType[0]}</>}
                                        {" "}{bookTemplate?.book?.betLine}</div>}
                                    <div className={sportBookBet[betIndex]?.betType == "ML" ? "bold m-t-13" : ""}>{bookTemplate?.book?.moneyLine > 0 ? "+" + bookTemplate?.book?.moneyLine : bookTemplate?.book?.moneyLine}</div>

                                </span> :
                                    <span>
                                        {sportBookBet[betIndex]?.side?.includes("Over") ? "O " : "U "}{bookTemplate?.book?.betLine}
                                        <div>{bookTemplate?.book?.moneyLine > 0 ? "+" + bookTemplate?.book?.moneyLine : bookTemplate?.book?.moneyLine}</div>
                                    </span>}
                            </div>
                            <div className={"col-3 df " + (bookTemplate?.template?.betStamp ? "p-l-10" : "p-l-20")} style={{ alignItems: "center", borderTopRightRadius: "4px", borderBottomRightRadius: "4px", background: bookTemplate?.template?.color }}>
                                <img src={bookTemplate?.template?.logo} width="36px" height="36px" />
                            </div>
                        </div>
                        {bookTemplate?.template?.betStamp && <div className="col-5" key={key + 1}>
                            <div onClick={(e) => setSportBookSignup({ src: bookTemplate?.template?.betStamp, sb: bookTemplate?.template?.sportsbook, openIn: bookTemplate?.template?.betStampOpen ?? "in-app" })} className="fs-13 yellow fw-500 p-l-7 p-r-5 mt-20" >
                                View {bookTemplate?.template?.sportsbook} Sign-Up Offers
                            </div>

                        </div>}
                    </div>

                )}
                <div className="hr-sect">OR</div>
            </div>}

            {props?.showOnboardingNotifications == ON_BOARDING[6] && <div className="container" style={{ marginTop: "20px" }}>
                {onBoardingOdds.map((bookTemplate, key) =>
                    <div className="df m-b-08" key={key} >
                        <div className={"bet p-0 df col-12"} onClick={(e) => selectBet(e, bookTemplate)}>
                            <div className={`col-12 p-l-0 df fdc p-r-0 text-center p-t-06 leftBox`} >
                                {["PS", "OU", "ML"]?.includes(currentBetType) ? <span>
                                    {console.log({ currentBetType, betType: sportBookBet[betIndex]?.betType, betIndex })}
                                    {sportBookBet[betIndex]?.betType == "PS" && <div className="bold">{bookTemplate?.book?.betLine > 0 ? "+" + bookTemplate?.book?.betLine : bookTemplate?.book?.betLine}</div>}
                                    {sportBookBet[betIndex]?.betType == "OU" && <div className="bold">
                                        {betIndex == 0 && <>{"U"}</>}
                                        {betIndex == 1 && <>{"O"}</>}
                                        {" "}{bookTemplate?.book?.betLine}</div>}
                                    <div className={sportBookBet[betIndex]?.betType == "ML" ? "bold m-t-13" : ""}>{bookTemplate?.book?.moneyLine > 0 ? "+" + bookTemplate?.book?.moneyLine : bookTemplate?.book?.moneyLine}</div>

                                </span> :
                                    <span>
                                        {sportBookBet[betIndex]?.side?.includes("Over") ? "O " : "U "}{bookTemplate?.book?.betLine}
                                        <div>{bookTemplate?.book?.moneyLine > 0 ? "+" + bookTemplate?.book?.moneyLine : bookTemplate?.book?.moneyLine}</div>
                                    </span>}
                            </div>
                        </div>
                    </div>

                )}
            </div>}
            {/* add bet */}
            {(addOdds && !ON_BOARDING?.includes(props?.showOnboardingNotifications)) &&
                <div className="container" style={{ marginTop: "2rem" }}>
                    {currentBetType == "PS" &&
                        <div>
                            <div style={{ marginBottom: "10px", display: "flex" }}>
                                <button style={{ margin: "0px 2px 0", padding: "13px 21px" }} className={pointSpread.sign == "+" ? "historyLeagueLeftActiveGray" : "historyLeagueLeft"} value={"+"}
                                    onClick={(e) => { setPointSpread({ ...pointSpread, sign: e.target.value }) }}
                                > {"+"} </button>
                                <button style={{ margin: "0px 12px 0 0", padding: "13px 21px" }} className={pointSpread.sign == "-" ? "historyLeagueLeftRightGray" : "historyLeagueRight"} value={"-"}
                                    onClick={(e) => { setPointSpread({ ...pointSpread, sign: e.target.value }) }}
                                > {"-"} </button>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                    <label style={{ marginBottom: "0.22rem", fontSize: "11px", color: "white" }} >Point Spread</label>
                                    <input
                                        type="number"
                                        autoCapitalize="none"
                                        className="enterSportbook"
                                        enterKeyHint="Point Spread"
                                        required
                                        value={pointSpread.point}
                                        onChange={(e) => {
                                            let number = e.target.value;
                                            if (number == 0 || (number % parseInt(number) == 0) || ((number % parseInt(number)) * 10 == 5)) {
                                                setPointSpread({ ...pointSpread, point: number })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginTop: "1.5rem", marginBottom: "10px", display: "flex" }}>
                                <button style={{ margin: "0px 2px 0", padding: "13px 21px", color: "black" }} className="historyLeagueLeftActive">{"+"}  </button>
                                <button style={{ margin: "0px 12px 0 0", padding: "13px 21px", color: "black" }} className="historyLeagueLeftActive"> {"-"} </button>
                                <div style={{ position: "fix", left: "31%", width: "100%" }}>
                                    <FormControl sx={{ width: '100%' }} variant="standard">
                                        <label style={{ marginBottom: "0.22rem", fontSize: "11px", color: "white" }} >Moneyline</label>
                                        <span className="minusInput">-<input
                                            autoCapitalize="none"
                                            className="enterSportbook"
                                            enterKeyHint="Moneyline"
                                            style={{ borderBottom: "none" }}
                                            value={moneyline.point}
                                            onChange={(e) => {
                                                let number = e.target.value;
                                                if (number == 0 || (number % parseInt(number) == 0) || ((number % parseInt(number)) * 10 == 5)) {
                                                    setMoneyLine({ sign: "-", point: number })
                                                }
                                            }}
                                            inputlabelprops={{
                                                style: { color: '#fff' },
                                            }}
                                        />
                                        </span>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    }

                    {(currentBetType == "ML" || (["YES", "NO"]?.includes(sportBookBet?.[betIndex]?.side) && ["OP", "DP", "GP"]?.includes(currentBetType))) &&
                        <div style={{ marginBottom: "10px", display: "flex" }}>
                            <button style={{ margin: "0px 2px 0", padding: "13px 21px" }} className={moneyline.sign == "+" ? "historyLeagueLeftActiveGray" : "historyLeagueLeft"} value={"+"}
                                onClick={(e) => { setMoneyLine({ ...moneyline, sign: e.target.value }) }}
                            > {"+"} </button>
                            <button style={{ margin: "0px 12px 0 0", padding: "13px 21px" }} className={moneyline.sign == "-" ? "historyLeagueLeftRightGray" : "historyLeagueRight"} value={"-"}
                                onClick={(e) => { setMoneyLine({ ...moneyline, sign: e.target.value }) }}
                            > {"-"} </button>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                <label style={{ marginBottom: "0.22rem", fontSize: "11px", color: "white" }} >MoneyLine</label>
                                <input
                                    type="number"
                                    autoCapitalize="none"
                                    className="enterSportbook"
                                    enterKeyHint="MoneyLine"
                                    required
                                    value={moneyline.point}
                                    onChange={(e) => {
                                        let number = e.target.value;
                                        if (number == 0 || (number % parseInt(number) == 0) || ((number % parseInt(number)) * 10 == 5)) {
                                            setMoneyLine({ ...moneyline, point: number })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    }


                    {(currentBetType == "OU" || (["Over", "Away-Over", "Away-Under", "Home-Over", "Home-Under", "Under"]?.includes(sportBookBet?.[betIndex]?.side) && ["OP", "DP", "GP"]?.includes(currentBetType))) &&
                        <div className="df">
                            <div className="col-6" >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label style={{ marginBottom: "0.22rem", fontSize: "11px", color: "white" }} >Total points</label>
                                    <span className="minusInput"><input
                                        type="number"
                                        autoCapitalize="none"
                                        className="enterSportbook"
                                        enterKeyHint="Point Spread"
                                        style={{ borderBottom: "none" }}
                                        required
                                        value={pointSpread.point}
                                        onChange={(e) => {
                                            let number = e.target.value;
                                            if (number == 0 || (number % parseInt(number) == 0) || ((number % parseInt(number)) * 10 == 5)) {
                                                setPointSpread({ sign: "+", point: number })
                                            }
                                        }}
                                    /></span>
                                </div>
                            </div>
                            <div className="col-6" >
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <label style={{ marginBottom: "0.22rem", fontSize: "11px", color: "white" }} >Moneyline</label>
                                    <span className="minusInput"><input
                                        autoCapitalize="none"
                                        className="enterSportbook"
                                        enterKeyHint="Point Spread"
                                        style={{ borderBottom: "none" }}
                                        required
                                        value={"-" + moneyline.point}
                                        onChange={(e) => {
                                            let number = e.target.value;
                                            if (number.length > 1)
                                                number = number.split("-")[1];
                                            if (number == "-")
                                                setMoneyLine({ sign: "-", point: "" })
                                            if (number == 0 || (number % parseInt(number) == 0) || ((number % parseInt(number)) * 10 == 5)) {
                                                setMoneyLine({ sign: "-", point: number })
                                            }
                                        }}
                                    /></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>}


            {/* custom sport book */}
            {!ON_BOARDING?.includes(props?.showOnboardingNotifications) && <div className="container" style={{ marginBottom: "3rem", marginTop: "2rem" }}>
                <div className="addBet df dfr p-0" >
                    {!addOdds ?
                        (["PS", "OU", "ML"]?.includes(sportBookBet?.[betIndex]?.betType) || (["Over", "Away-Over", "Away-Under", "Home-Over", "Home-Under", "Under", "YES", "NO"]?.includes(sportBookBet?.[betIndex]?.side) && ["OP", "DP", "GP"]?.includes(currentBetType)))
                        && <button className="applyFilter" style={{ fontWeight: "500" }} onClick={() => setAddOdds(true)}>
                            OR ENTER YOUR ODDS
                        </button> :
                        <button
                            disabled={!((currentBetType == "ML" && moneyline.sign && moneyline.point) || (pointSpread.sign && pointSpread.point && moneyline.sign && moneyline.point))}
                            className="applyFilter" style={{
                                fontWeight: "500", backgroundColor: "#00ba07",

                                opacity: ((currentBetType == "ML" && moneyline.sign && moneyline.point) || (pointSpread.sign && pointSpread.point && moneyline.sign && moneyline.point)) ? "1" : "0.5"
                            }} onClick={(e) => createBet(e)}>
                            SUBMIT
                        </button>}
                </div>
            </div>}

            {/* signip sportbook button */}
            {!addOdds && signupOfferURL && !ON_BOARDING?.includes(props?.showOnboardingNotifications) &&
                <div className="container p-0 text-center signupOfferCon" onClick={(e) => setSportBookSignup({ src: signupOfferURL, sb: "Offers", openIn: "browser" })}>
                    <div className="fs-14 white pt-4 ibm" >
                        {String("Click here to see")}
                    </div>
                    <div className="fs-18 yellow fw-500 ibm" >
                        {String("all sportsbook sign-up offers").toUpperCase()}
                    </div>
                </div>}
        </>
    )
}
