import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

const GameSegAbb = [
    ["FG", "FULL GAME"],
    ["1H", "1ST HALF"],
    ["2H", "2ND HALF"],
    ["1Q", "1ST QTR"],
    ["2Q", "2ND QTR"],
    ["3Q", "3RD QTR"],
    ["4Q", "4TH QTR"],
    ["1P", "1ST PD"],
    ["2P", "2ND PD"],
    ["3P", "3RD PD"],
    ["F5", "FIRST 5"],
];
const betType = [
    ["PS", "POINTSPREAD"],
    ["ML", "MONEYLINE"],
    ["OU", "TOTAL"]
]
const betTypes = [
    ["PS", "POINT SPREAD"],
    ["ML", "MONEY LINE"],
    ["OU", "TOTAL"],
];

const outcomes = [
    ["W", "WINS"],
    ["L", "LOSSES"],
    ["T", "TIES"]
]

export const _getBetsForAction = async (userId, league, context, token) => {
    const headers = { Authorization: token }

    const requestURL = serverURL + "auth/bets/list"
    let params = _({
        userId, league, context
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.get(requestURL, { params, headers });
    response = response.data;
    return response;
}
export const _getBetsForActionLive = async ({ userId, league, betType, gmSeg, token }) => {
    const headers = { Authorization: token }
    const requestURL = serverURL + "games/live"
    let body = _({
        userId, league: league ? [league] : null, betType, gmSeg
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    let response = await axios.put(requestURL, body, { headers })
    response = response.data;
    return response;
}

export const _getBetsHistory = async (context, filters, userId, token) => {
    const headers = { Authorization: token }
    const requestURL = serverURL + "auth/bets/"
    let params = _({
        userId,
        context,
        league: _.get(filters, "league", null),
        betType: filters.bet ? betType.filter(abb => abb[1] == filters.bet)?.[0]?.[0] : null,
        gmSeg: filters.gmSeg ? GameSegAbb.filter(abb => abb[1] == filters.gmSeg)?.[0]?.[0] : null,
        fromDate: _.get(filters, "fromDate", null),
        thruDate: _.get(filters, "thruDate", null),
        wlt: filters.outcomes ? outcomes.filter(abb => abb[1] == filters.outcomes)?.[0]?.[0] : null,
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    let response = await axios.get(requestURL, { params, headers });
    response = response.data;
    return response;
}

export const _getBetsoFActiveGameDashboard = async (gameId, userId) => {
    const requestURL = serverURL + "odds"
    let params = _({
        gameId, userId
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    let response = await axios.get(requestURL, { params });
    response = response.data;
    return response;
}

export const _getSportBookodds = async (gameId, betoptionsId, selection = null) => {
    try {
        const requestURL = serverURL + "oddsjam/markets";
        let params = _({
            gameId, selection
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        params.betoptionsId = betoptionsId
        let response = await axios.get(requestURL, { params });
        response = response.data;
        return response;
    } catch (error) {

    }
}

export const _getOnBoardingSportBookodds = async (gameId, betoptionsId, sbookId) => {
    try {
        const requestURL = serverURL + "games/obodds";
        let params = _({
            gameId, betoptionsId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).value();
        let response = await axios.get(requestURL, { params });
        response = response.data;
        return response;
    } catch (error) {

    }
}

export const _getDraftBetsCount = async (userId, gameId = null, propbets = null) => {
    const requestURL = serverURL + "bets/drafts"
    const headers = {}
    let params = _({
        userId,
        gameId,
        propbets
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    if (propbets != null)
        params.propbets = propbets
    try {
        var response = await axios.get(requestURL, { headers, params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}

export const _getBetType = async (betTyp, gamSeg, side) => {
    const requestURL = serverURL + "betoptions"
    const headers = {}
    let params = _({
        betType: betTypes.filter(abb => abb[1] == betTyp)?.[0]?.[0],
        gmSeg: GameSegAbb.filter(abb => abb[1] == gamSeg)?.[0]?.[0],
        side
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    try {
        var response = await axios.get(requestURL, { headers, params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}

export const _getSingleBetType = async (betoptionsId) => {
    const requestURL = serverURL + "betoptions"
    const headers = {}
    let params = {
        betoptionsId
    };
    try {
        var response = await axios.get(requestURL, { headers, params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}
export const _createBet = async (userId, gameId, betoptionsId, betline, moneyline, sbookId, confidence, marketId, sbookLink, betStatus = null) => {

    const body = { userId: userId.toString(), gameId, betoptionsId, betline, moneyline, sbookId, sbookLink }
    if (betStatus)
        body.betStatus = betStatus;
    if (confidence)
        body.confidence = confidence;
    if (marketId)
        body.marketId = marketId
    const requestURL = serverURL + "bets";
    let response = await axios.post(requestURL, body)
    response = response?.data?.newBet ?? null
    return response
}

export const _createContestBet = async (user, gameId, betoptionsId, betline, moneyline, contestId) => {

    try {
        const body = { user, gameId, betoptionsId, betline, moneyline, contestId }

        const requestURL = serverURL + "contestbets";
        let response = await axios.post(requestURL, body);
        response = response?.data?.newRecord ?? null
        return response
    } catch (error) {
        return {};
    }

}

export const _createHistoryBet = async (data) => {


    const body = { ...data }
    const requestURL = serverURL + "bets";
    try {
        var response = await axios.post(requestURL, body)
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}
export const _updateBetSportBook = async (_id, sbookId, betline, moneyline) => {
    const headers = { Authorization: localStorage.getItem("token") }
    const body = {
        _id, sbookId,
        betline: betline ? String(betline) : "0",
        moneyline
    }
    const requestURL = serverURL + "auth/bets";
    let response = await axios.put(requestURL, body, { headers })
    response = response?.data
    return response
}

export const _changeBetStatus = async (_id, betStatus) => {
    const headers = { Authorization: localStorage.getItem("token") }
    const body = {
        _id,
        betStatus
    }
    const requestURL = serverURL + "auth/bets";
    let response = await axios.put(requestURL, body, { headers })
    response = response?.data
    return response
}

export const _deleteBet = async (_id) => {
    const headers = { Authorization: localStorage.getItem("token") }

    const params = { _id }
    const requestURL = serverURL + "/bets";
    let response = await axios.delete(requestURL, { params, headers })
    response = response.data
    return response
}

export const _getSingleBet = async (_id) => {
    const params = { _id }
    const requestURL = serverURL + "bets";
    let response = await axios.get(requestURL, { params })
    response = response.data
    return response
}

export const _deleteContestBet = async (_id) => {
    const params = { _id }
    const requestURL = serverURL + "contestbets";
    let response = await axios.delete(requestURL, { params })
    response = response.data
    return response
}

export const _checkBetGrid = async (userId, token) => {
    const headers = { Authorization: token }

    const params = { userId }
    const requestURL = serverURL + "bets/on2step2";
    let response = await axios.put(requestURL, {}, { params })
    response = response?.data
    return response
}

export const _betStatusChanged = async (_id, action, betStatus, betAmount, token) => {
    const headers = { Authorization: token }
    let body = _({
        _id, action, betStatus, betAmount
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    if (betAmount && betAmount >= 0)
        body.betAmount = betAmount
    const requestURL = serverURL + "auth/bets";
    let response = await axios.put(requestURL, body, { headers })
    response = response?.data
    return response
}

export const _betRecsStatusChanged = async (id, betStatus) => {
    const headers = { Authorization: localStorage.getItem("token") }
    let body = _({
        id, betStatus
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    const requestURL = serverURL + "recs";
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response
}

export const _recsBetStatusChanged = async ({ _id, betStatus, moneyline, betline }) => {
    betline = betline ? String(betline) : "0";
    const body = { _id, betStatus, moneyline, betline }
    const requestURL = serverURL + "recs";
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response
}

export const _on2step3 = async (userId) => {
    const headers = { Authorization: localStorage.getItem("token") }

    const params = { userId }
    const requestURL = serverURL + "auth/bets/on2step3";
    let response = await axios.put(requestURL, {}, { params, headers })
    response = response?.data
    return response
}

export const _newOwner = (userId, newUser) => {
    const headers = { Authorization: localStorage.getItem("token") }
    const body = { userId, newUser }
    const requestURL = serverURL + "auth/bets/newowner";
    let response = axios.put(requestURL, body, { headers })
    response = response?.data
    return response
}

export const _circleBets = async (circle) => {
    try {
        const requestURL = serverURL + "comments/circlebets"
        let params = _({
            circle
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        if (response?.data && response?.data?.length)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getContestUserBets = async (contestId, user,owner) => {
    const requestURL = serverURL + "contestbets"

    let params = {
        contestId, user,owner
    }

    try {
        var response = await axios.get(requestURL, { params });
        if (response.data && response.data.length)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}