import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _getInsights = async (userId) => {
    const requestURL = serverURL + "insights"
    let params = _({
        userId,
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.get(requestURL, { params });
    if(response.data)
        return {success:true,data:response.data};
    else
        return {success:false};
}

export const _markAsRead = async (userId) => {
    const requestURL = serverURL + "insights/markread"
    let body = _({
        userId,
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.put(requestURL, body);
    if(response.data)
        return {success:true,data:response.data};
    else
        return {success:false};
}