import React from "react";


export const PoppingText = ({text,margin}) => {
    const words = text.split(' ').map((word, index) => (
      word == "\n" ? <br key={index} /> :
        <span key={index} className="word" style={{ marginRight:margin??"5px", animationDelay: `${index * 0.01}s` }}>
          {word}
        </span>
    ));

    return <div>{words}</div>;
  };