// const server= "http://localhost:4000/";
const server= "https://prod.bettortakes.com/";

exports.gamesLists = "https://inyvncq33e.execute-api.us-east-2.amazonaws.com/dev/getOdds/";
exports.getLeaguesURL = `${server}api/leagues`;
exports.getStatsURL = `${server}api/edge/stats`;
exports.serverURL=`${server}api/`;
exports.APIKEY="#a$Rt&*o@#$r99SDG29@452_)@#$7MD@#5e5@#$><{b:1c:8a>23%78//75.c,70a3@#$02asd@#$gf304@#e22";
exports.ON_BOARDING=[
    "VIDEO_INTRO",
    "FIRST_MESSAGE",
    "PICK_A_LEAGUE",
    "SELECT_A_BET",
    "PICK_A_BET",
    "SELECT_CONFIDENCE",
    "SELECT_BET",
    'EDGE-SCORE',
    "GRID",
    "Signup"
]