import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _getLive_UpcomingGames = async (context = "bettable") => {
    const requestURL = serverURL + "games"
    let params = _({
        context
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.get(requestURL, { params });
    response = response.data;

    return response;
}

export const _getGames = async (game, userId, gameId) => {
    try {
        // posts
        const requestURL = serverURL + "games";
        let params = _({
            context: "bettable",
            league: game,
            userId,
            gameId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        if (!userId && localStorage.getItem("userRandomId"))
            params.userId = localStorage.getItem("userRandomId")
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getPropGameBets = async (gameId) => {

    try {
        // posts
        const requestURL = serverURL + "oddsjam/propbets";
        let params = _({
            gameId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getContestGames = async (contestId, userId) => {

    try {
        // posts
        const requestURL = serverURL + "contests/games";
        let params = _({
            contestId,
            userId,
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getContestCountGames = async (contestId, user) => {

    try {
        // posts
        const requestURL = serverURL + "contestbets";
        let params = _({
            contestId,
            user,
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getGamesForChat = async (league, gameIds) => {

    try {
        // posts
        const requestURL = serverURL + "games/notin";
        let body = {
            league,
            notIn: gameIds
        }
        var response = await axios.put(requestURL, body);
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}


export const _getGameGrid = async (betDoc) => {
    // posts
    try {
        const requestURL = serverURL + "edge";
        let params = {
            betDoc
        }
        var response = await axios.get(requestURL, { params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}

export const _getGameDesc = async (league, gameDate) => {
    // posts
    try {
        const requestURL = serverURL + "games";
        let params = {
            league, gameDate
        }
        var response = await axios.get(requestURL, { params });
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (e) {
        return { success: false };
    }
}