import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");


export const _getSubscriptionPlans = async (subscription) => {
    const requestURL = serverURL + "subscriptions"
    let params = _({
        // active:true,
        // subscription
    }).omitBy(_.isUndefined).value();
    var response = await axios.get(requestURL,{params});
    if (response.data)
        return { success: true, data: response.data };
    else
        return { success: false };
}