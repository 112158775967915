import { Route, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { shallow } from 'zustand/shallow';
import { useStore } from "../store";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { userData,setUpdateStore } = useStore(store => ({
    userData: store?.userData,
    setUpdateStore:store?.setUpdateStore
  }), shallow);
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    if (userData?.userId) {
      setStatus(userData?.userId);
    }
    setLoader(false)
    setUpdateStore()
  }, []);
    return (
      <>
        {!loader && (
          <Route
            {...rest}
            render={(props) =>
              userData?.userId ? <Component {...props} /> : <Redirect to="/" />
            }
          />
        )}
      </>
    )
  
}
