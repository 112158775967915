import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
    },
  },
});

const HeaderTabs = (props) => {
  const [tabsData, setTabs] = useState([]);

  // set tab theme
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flexGrow: 1,
  //     width: "100%",
  //     backgroundColor: theme.palette.background.paper,
  //   },
  // }));
  // const classes = useStyles();

  useEffect(() => {
    setTabs(props.header || []);
  }, []);

  return (
    <div >
      <AppBar position="static" color="default">
        <ThemeProvider theme={theme}>

          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: "black", background: "black" } }}
            textColor="primary"
            value={props.navTab}
            onChange={props.onTabChanged}
            style={{borderBottom:"1px solid #cccccc"}}
            // indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Games list"
          >
            {tabsData.map((tabInfo, index) => (
              <Tab
                label={tabInfo.label}
                id={`simple-tab-${index}`}
                key={index}
                icon={tabInfo.icon ? <span style={{
                  minWidth: tabInfo.icon < 100 ?"24px" : tabInfo.icon < 1000 ? "40px" :"50px",
                  height: "24px",
                  display:"inline-table",
                  marginTop:"2px",
                  borderRadius: "6px",
                  backgroundColor: props.navTab ==1 ? "#000" :"rgb(104 103 103)"
                }}>
                  <span style={{
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "500",
                    fontSize: "18px",
                    color: "#fff"
                  }}>

                    {tabInfo.icon}
                  </span>
                </span> : ""}
                iconPosition="end"
                className="active"
                disabled={tabInfo.label ? false : true}
                aria-controls={`simple-tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </ThemeProvider>
      </AppBar>
    </div>
  );
};

export { HeaderTabs };
