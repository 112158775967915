import React, { useState, useEffect } from "react";
import _ from "lodash";
import { GameTile } from "./gameTile";
import { BetScroll } from "./betScroll";
import { _getGames, _getBetsoFActiveGameDashboard } from "../../../services";
import SportBookMenu from "./sportBookMenu";
import ConfidenceInfo from "./confidenceInfo";
import back from "../../../img/close2.png"
import loaderGIF from "../../../img/newLoader.gif";
export const Game = ({ currentLeague, setBetCount, sportBooks, userPlan, createBet, deleteCurrentBet, userSetting, setUserSetting, game, history, ...props }) => {


    // error messages
    const [showMessage, setShowMessage] = useState(false); //boolean to show messages
    const [message, setMessage] = useState(""); //main message shown in 1st line
    const [submessage, setSubMessage] = useState(""); //small message shown in 2nd line

    // hold bets to show on tiles
    const [pointSpreadBets, setPointSpreadBets] = useState([]); //point spread bets
    const [gameTotalBets, setGameTotalBets] = useState([]); //game total bets
    const [moneyLineBets, setMoneyLineBets] = useState([]); //moneyline bets 
    const [firstHalfTotalBets, setFirstHalfTotalBets] = useState([]); //first half total bets
    const [firstHalfMoneyLine, setFirstHalfMoneyLineBets] = useState([]); //first half total bets
    const [firstHalfSpread, setFirstHalfSpreadBets] = useState([]); //first half spread bets
    const [gamePropBets, setGamePropBets] = useState([]); //moneyline bets 
    const [propBet1, setPropBet1] = useState("")
    const [propBet2, setPropBet2] = useState("")
    const [propBet3, setPropBet3] = useState("")

    // these are the bets that are created by the user
    const [betPointSpread, setBetPointSpread] = useState({});
    const [betMoneyLine, setBetMoneyLine] = useState({})
    const [betGameTotal, setBetGameTotal] = useState({});
    const [bet1HfSpread, setBet1HSpread] = useState({});
    const [bet1HfMoneyLine, setBet1HMoneyLine] = useState({});
    const [bet1HfTotal, setBet1HTotal] = useState({});
    const [betGameProp, setBetGameProp] = useState({});

    // confirm dialog
    const [deleteScreen, setDeleteScreen] = useState(false);
    const [deleteBet, setDeleteBet] = useState({ function: "", currentBet: {}, newBet: {}, index: 2 })


    // sportbook overlay
    const [openSportBook, setOpenSportBook] = useState(false);
    const [showToolTip, setShowToolTip] = useState(false);
    const [sportBookGame, selectSportBookGame] = useState({})
    const [sportBookSection, setSportBookSection] = useState("")
    const [betIndex, setBetIndex] = useState(null)
    const [betCreatedFor, setBetCreatedFor] = useState(null)
    const [sportBookBet, setSportBookBet] = useState({})
    const [betType, setBetType] = useState("");

    const [sportBooksignup, setSportBookSignup] = useState({});
    const [isLoadingIFrame, setIsLoadingIFrame] = useState(true);
    // show bets
    const [showBets, setShowBets] = useState(false);
    const [alreadyCreatedBets, setAlreadyCreatedbets] = useState([])

    useEffect(async () => {
        await decorateBets(game)
        setShowBets(true)
    }, []);


    const isBetAlreadyCreated = (alreadyCreatedBet, betOptionId) => {
        let createdBet = alreadyCreatedBet.find(bet => bet.betoptionsId == betOptionId)
        if (!_.isEmpty(createdBet)) {
            const { _id: betId, betline, betoptionsId, moneyline, sbookId } = createdBet;
            return { betId, betline, betoptionsId, moneyline, sbookId }
        }
        else
            return {}
    }


    const decorateBets = async (selectedGame) => {
        let alreadyCreatedBet = []
        if (selectedGame?.bets?.length > 0) {
            setAlreadyCreatedbets(selectedGame.bets)
            alreadyCreatedBet = selectedGame.bets
        }
        if (selectedGame.status == "DELAYED" && selectedGame?.livePeriod == 0)
            selectedGame.status = "SCHEDULED";
        else if (selectedGame.status == "DELAYED" && selectedGame?.livePeriod == 0)
            selectedGame.status = "IN_PROGRESS";
        // show 5 pair of bets (point 1)
        if (selectedGame.status == "SCHEDULED") {

            // find bets
            let betOption1 = {},
                betOption2 = {},
                betOption3 = {},
                betOption4 = {},
                betOption5 = {},
                betOption6 = {},
                betOption7 = {},
                betOption8 = {},
                betOption9 = {},
                betOption10 = {},
                betOption11 = {},
                betOption12 = {},
                betOption100 = {},
                betOption101 = {};
            setPropBet1(selectedGame?.prop1 ?? "")
            setPropBet2(selectedGame?.prop2 ?? "")
            setPropBet3(selectedGame?.prop3 ?? "")

            for (let index = 0; index < game?.bestOdds?.length; index++) {
                const sBet = game?.bestOdds[index];
                // point spread
                if (sBet.betoptionsId == 1) {
                    betOption1 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 1)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetPointSpread({ betId: bet.betId, action: "T", isOlderBet: true, ...bet, index: 0 })
                    }
                } else if (sBet.betoptionsId == 2) {
                    betOption2 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 2)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetPointSpread({ betId: bet.betId, action: "T", isOlderBet: true, ...bet, index: 1 })
                    }
                } else if (sBet.betoptionsId == 3) {
                    betOption3 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 3)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetMoneyLine({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                } else if (sBet.betoptionsId == 4) {
                    betOption4 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 4)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetMoneyLine({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                } else if (sBet.betoptionsId == 5) {
                    betOption5 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 5)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetGameTotal({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                } else if (sBet.betoptionsId == 6) {
                    betOption6 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 6)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetGameTotal({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                } else if (sBet.betoptionsId == 7) {
                    betOption7 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 7)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HSpread({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                } else if (sBet.betoptionsId == 8) {
                    betOption8 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 8)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HSpread({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                } else if (sBet.betoptionsId == 9) {
                    betOption9 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 9)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HMoneyLine({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                }
                else if (sBet.betoptionsId == 10) {
                    betOption10 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 10)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HMoneyLine({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                }
                else if (sBet.betoptionsId == 11) {
                    betOption11 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 11)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HTotal({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                } else if (sBet.betoptionsId == 12) {
                    betOption12 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 12)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBet1HTotal({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                }
                else if (sBet.betoptionsId == 100) {
                    betOption100 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 100)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetGameProp({ betId: bet.betId, action: "T", isOlderBet: true, index: 0, ...bet })
                    }
                } else if (sBet.betoptionsId == 101) {
                    betOption101 = sBet;
                    const bet = isBetAlreadyCreated(alreadyCreatedBet, 101)
                    // when bet already created
                    if (!_.isEmpty(bet)) {
                        setBetGameProp({ betId: bet.betId, action: "T", isOlderBet: true, index: 1, ...bet })
                    }
                }
            }
            // set state of 3 pair
            setPointSpreadBets([betOption1 ?? null, betOption2 ?? null]);
            setGameTotalBets([betOption5 ?? null, betOption6 ?? null]);
            setMoneyLineBets([betOption3 ?? null, betOption4 ?? null]);
            // set state of MMA Bet
            setGamePropBets([betOption100 ?? null, betOption101 ?? null]);
            // game have 1st half (point 1.2)
            if (currentLeague?.gmSegs.includes("1H")) {
                setFirstHalfMoneyLineBets([betOption9 ?? null, betOption10 ?? null]);
                setFirstHalfTotalBets([betOption11 ?? null, betOption12 ?? null]);
                setFirstHalfSpreadBets([betOption7 ?? null, betOption8 ?? null]);
            }
            // We are no longer suppoer second half games
            // if (currentLeague?.gmSegs.includes("2H"))
            //     secondHalfBetCreata(selectedGame, bet);
        }
        // When game is over (2nd point)
        else if (selectedGame.status == "FINAL" || selectedGame.status == "FULL_TIME") {
            setMessage("GAME IS ALREADY FINAL");
            setSubMessage("Entering historical bets is coming soon");
            setShowMessage(true);
            return;
        }
        else {
            // if league don't contain 2H means: this game don't have halfs
            if (!currentLeague?.gmSegs.includes("2H")) {
                setMessage("No more bets available");
                setSubMessage("Live betting coming in future release.");
                setShowMessage(true);
            }

        }


    };


    // isConfirm = false if you want to show confirm message again
    const pointSpreadBetsClick = async (bet, index, isConfirmed = true) => {
        // e.preventDefault()
        try {
            let completeBet = bet;
            bet = bet[index];
            // when no any bet created, just create a new one
            if (_.isEmpty(betPointSpread)) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setPointSpreadBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetPointSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // The bet is belong to current session
            else if (betPointSpread.isOlderBet == false) {
                deleteCurrentBet(betPointSpread.betId, false);
                // create new one if the opposite side bet selected
                if (betPointSpread.index != index) {
                    const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                    const { betline, moneyline } = betCreated;
                    completeBet[index].betId = betCreated._id
                    setPointSpreadBets(completeBet)
                    bet.sbookId = bet.bestSportBook[0].sbookId
                    setBetPointSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
                }
                // just want to delete
                else {
                    setBetCount(-1)
                    setBetPointSpread({})
                }
            }
            // bet not belong to current session and click on I'M SURE button
            else if (betPointSpread.isOlderBet && isConfirmed) {
                deleteCurrentBet(betPointSpread.betId, false);
                // want to create opposite side bet
                if (betPointSpread.index != index) {
                    const betCreated = await createBet(bet?.betoptionsId, bet?.bestSportBook[0], true, game, bet?.confidence)
                    const { betline, moneyline } = betCreated;
                    completeBet[index].betId = betCreated._id
                    setPointSpreadBets(completeBet)
                    bet.sbookId = bet.bestSportBook[0].sbookId
                    setBetPointSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
                }
                // just want to delete
                else {
                    setBetCount(-1)
                    setBetPointSpread({})
                }
            }
            // show delete modal
            else {
                // set data for conifrm call
                setDeleteBet({ function: pointSpreadBetsClick, currentBet: betPointSpread, newBet: completeBet, index })
                setDeleteScreen(true)
            }
        } catch (e) {
            console.log(e);
        }


    }
    // isConfirm = false if you want to show confirm message again
    const gameTotalClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];
        // when no any bet created, just create a new one
        if (_.isEmpty(betGameTotal)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setGameTotalBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBetGameTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (betGameTotal.isOlderBet == false) {
            deleteCurrentBet(betGameTotal.betId, false);
            // create new one if the opposite side bet selected
            if (betGameTotal.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setGameTotalBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetGameTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBetCount(-1)
                setBetGameTotal({})
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (betGameTotal.isOlderBet && isConfirmed) {
            deleteCurrentBet(betGameTotal.betId, false);
            // want to create opposite side bet
            if (betGameTotal.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setGameTotalBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetGameTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBetGameTotal({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: gameTotalClick, currentBet: betGameTotal, newBet: completeBet, index })
            setDeleteScreen(true)
        }
    }
    // isConfirm = false if you want to show confirm message again
    const moneyLineClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];

        // when no any bet created, just create a new one
        if (_.isEmpty(betMoneyLine)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setMoneyLineBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBetMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (betMoneyLine.isOlderBet == false) {
            deleteCurrentBet(betMoneyLine.betId, false);
            // create new one if the opposite side bet selected
            if (betMoneyLine.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setMoneyLineBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBetMoneyLine({})
                setBetCount(-1)
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (betMoneyLine.isOlderBet && isConfirmed) {
            deleteCurrentBet(betMoneyLine.betId, false);
            // want to create opposite side bet
            if (betMoneyLine.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setMoneyLineBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBetMoneyLine({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: moneyLineClick, currentBet: betMoneyLine, newBet: completeBet, index })
            setDeleteScreen(true)
        }

    }
    // isConfirm = false if you want to show confirm message again
    const firstHaltMoneyLineClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];
        // when no any bet created, just create a new one
        if (_.isEmpty(bet1HfMoneyLine)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setFirstHalfMoneyLineBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBet1HMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (bet1HfMoneyLine.isOlderBet == false) {
            deleteCurrentBet(bet1HfMoneyLine.betId, false);
            // create new one if the opposite side bet selected
            if (bet1HfMoneyLine.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfMoneyLineBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBet1HMoneyLine({})
                setBetCount(-1)
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (bet1HfMoneyLine.isOlderBet && isConfirmed) {
            deleteCurrentBet(bet1HfMoneyLine.betId, false);
            // want to create opposite side bet
            if (bet1HfMoneyLine.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfMoneyLineBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HMoneyLine({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBet1HMoneyLine({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: firstHaltMoneyLineClick, currentBet: bet1HfMoneyLine, newBet: completeBet, index })
            setDeleteScreen(true)
        }

    }
    // isConfirm = false if you want to show confirm message again
    const firstHalfTotalClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];

        // when no any bet created, just create a new one
        if (_.isEmpty(bet1HfTotal)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setFirstHalfTotalBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBet1HTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (bet1HfTotal.isOlderBet == false) {
            deleteCurrentBet(bet1HfTotal.betId, false);
            // create new one if the opposite side bet selected
            if (bet1HfTotal.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfTotalBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBet1HTotal({})
                setBetCount(-1)
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (bet1HfTotal.isOlderBet && isConfirmed) {
            deleteCurrentBet(bet1HfTotal.betId, false);
            // want to create opposite side bet
            if (bet1HfTotal.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfTotalBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HTotal({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBet1HTotal({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: firstHalfTotalClick, currentBet: bet1HfTotal, newBet: completeBet, index })
            setDeleteScreen(true)
        }

    }
    // isConfirm = false if you want to show confirm message again
    const firstHalfSpreadClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];
        // when no any bet created, just create a new one
        if (_.isEmpty(bet1HfSpread)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setFirstHalfSpreadBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBet1HSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (bet1HfSpread.isOlderBet == false) {
            deleteCurrentBet(bet1HfSpread.betId, false);
            // create new one if the opposite side bet selected
            if (bet1HfSpread.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfSpreadBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBet1HSpread({})
                setBetCount(-1)
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (bet1HfSpread.isOlderBet && isConfirmed) {
            deleteCurrentBet(bet1HfSpread.betId, false);
            // want to create opposite side bet
            if (bet1HfSpread.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setFirstHalfSpreadBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBet1HSpread({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBet1HSpread({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: firstHalfSpreadClick, currentBet: bet1HfSpread, newBet: completeBet, index })
            setDeleteScreen(true)
        }

    }
    // isConfirm = false if you want to show confirm message again
    const gamePropClick = async (bet, index, isConfirmed = true) => {
        let completeBet = bet;
        bet = bet[index];
        // when no any bet created, just create a new one
        if (_.isEmpty(betGameProp)) {
            const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
            const { betline, moneyline } = betCreated;
            completeBet[index].betId = betCreated._id
            setGamePropBets(completeBet)
            bet.sbookId = bet.bestSportBook[0].sbookId
            setBetGameProp({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
        }
        // The bet is belong to current session
        else if (betGameProp.isOlderBet == false) {
            deleteCurrentBet(betGameProp.betId, false);
            // create new one if the opposite side bet selected
            if (betGameProp.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], false, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setGamePropBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetGameProp({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else {
                setBetCount(-1)
                setBetGameProp({})
            }
        }
        // bet not belong to current session and click on I'M SURE button
        else if (betGameProp.isOlderBet && isConfirmed) {
            deleteCurrentBet(betGameProp.betId, false);
            // want to create opposite side bet
            if (betGameProp.index != index) {
                const betCreated = await createBet(bet.betoptionsId, bet.bestSportBook[0], true, game, bet?.confidence)
                const { betline, moneyline } = betCreated;
                completeBet[index].betId = betCreated._id
                setGamePropBets(completeBet)
                bet.sbookId = bet.bestSportBook[0].sbookId
                setBetGameProp({ betId: betCreated._id, action: null, isOlderBet: false, index, betline, moneyline })
            }
            // just want to delete
            else { setBetCount(-1); setBetGameProp({}) }
        }
        // show delete modal
        else {
            // set data for conifrm call
            setDeleteBet({ function: gamePropClick, currentBet: betGameProp, newBet: completeBet, index })
            setDeleteScreen(true)
        }
    }
    const confirmDelete = (e) => {
        e.preventDefault()
        // hide screen
        setDeleteScreen(false);
        // call the call function to create/delete bet
        let bet = Object.assign({}, deleteBet.newBet), index = deleteBet.index;
        deleteBet.function(bet, index, true)
    }


    const sportBookOverlay = async (bet, section, game, index = null, betFor = null) => {
        setBetType(bet[index].betType)
        setSportBookBet(bet)
        setSportBookSection(section)
        selectSportBookGame(game)
        setBetIndex(index)
        setBetCreatedFor(betFor);
        setOpenSportBook(true)
    }

    const changeBetSportBook = async (sportBookTemplate, sportBook, confScale) => {
        sportBookBet[betIndex].bestSportBook = [sportBook]
        sportBookBet[betIndex].bestSportBookTemp = [sportBookTemplate]
        sportBookBet[betIndex].confidence = confScale;
        setOpenSportBook(false)

        if (sportBookSection == "POINT SPREAD")
            pointSpreadBetsClick(sportBookBet, betIndex)
        else if (sportBookSection == "GAME TOTAL")
            gameTotalClick(sportBookBet, betIndex)
        else if (sportBookSection == "MONEY LINE")
            moneyLineClick(sportBookBet, betIndex)
        else if (sportBookSection == "1ST HALF SPREAD")
            firstHalfSpreadClick(sportBookBet, betIndex)
        else if (sportBookSection == "1ST HALF MONEYLINE")
            firstHaltMoneyLineClick(sportBookBet, betIndex)
        else if (sportBookSection == "1ST HALF TOTAL")
            firstHalfTotalClick(sportBookBet, betIndex)
        else if (sportBookSection == "GAME PROP")
            gamePropClick(sportBookBet, betIndex)
    }

    const showConfidenceInfo = (e, show) => {
        e.preventDefault();
        setShowToolTip(show);
    }

    const setSportBookSignupPage = (sportBookData) => {

        if (window?.webkit?.messageHandlers?.setUserState) {
            window.webkit.messageHandlers.setUserState.postMessage({
                "key": "openBrowser",
                src: sportBookData.src,
                openIn: "browser" // browser or popup
            });
        }
        else if (window?.AndroidBridge?.openBrowser)
            window?.AndroidBridge?.openBrowser(sportBookData.src, "browser")
        else if (sportBookData?.openIn == "browser")
            window.open(sportBookData.src,)
        else
            setSportBookSignup(sportBookData)
    }
    return (
        <>
            <div>
                <div className="container p-0 dashboard" >
                    <GameTile {...game} currentLeague={currentLeague} showOnboardingNotifications={props?.showOnboardingNotifications} setShowOnboardingNotifications={props?.setShowOnboardingNotifications} />
                    {showBets && <BetScroll
                        game={game} pointSpreadBets={pointSpreadBets} betPointSpread={betPointSpread} pointSpreadBetsClick={pointSpreadBetsClick} sportBookOverlay={sportBookOverlay}
                        gameTotalBets={gameTotalBets} betGameTotal={betGameTotal} gameTotalClick={gameTotalClick}
                        moneyLineBets={moneyLineBets} betMoneyLine={betMoneyLine} moneyLineClick={moneyLineClick}
                        firstHalfSpread={firstHalfSpread} bet1HfSpread={bet1HfSpread} firstHalfSpreadClick={firstHalfSpreadClick}
                        firstHalfTotalBets={firstHalfTotalBets} bet1HfTotal={bet1HfTotal} firstHalfTotalClick={firstHalfTotalClick}
                        firstHalfMoneyLine={firstHalfMoneyLine} bet1HfMoneyLine={bet1HfMoneyLine} firstHaltMoneyLineClick={firstHaltMoneyLineClick}
                        gamePropBets={gamePropBets} betGameProp={betGameProp} gamePropClick={gamePropClick}
                        openSportBook={openSportBook} currentLeague={currentLeague}
                        userSetting={userSetting}
                        propBet1={propBet1} propBet2={propBet2} propBet3={propBet3}
                        history={history} userPlan={userPlan}
                        showOnboardingNotifications={props?.showOnboardingNotifications} setShowOnboardingNotifications={props?.setShowOnboardingNotifications}
                    />}
                    <hr style={{ marginBottom: 0 }} />
                </div>

                {/* Delete popup */}
                {deleteScreen && <div className={`overlay2 ${deleteScreen ? "openup" : ""}`}  >
                    <div className="container" style={{ marginTop: "62%" }}>
                        <div className="delete1">
                            YOU ARE ABOUT TO DELETE AN EARLIER TAKE<div style={{ marginTop: "20%" }} className="delete2">ARE YOU SURE?</div>
                        </div>
                        <div className="container df dfr p-0" style={{ justifyContent: "center", height: "52px", position: "fixed", zIndex: 1, width: "92%", bottom: "10%" }} >

                            <div className="cancelDel" onClick={(e) => setDeleteScreen(false)} style={{ zIndex: "5" }}>

                                CANCEL</div>
                            <div className="delete" onClick={e => confirmDelete(e)}>
                                I'M SURE
                            </div>

                        </div>
                    </div>
                </div>}
            </div>
            {/* sportbook overlay */}
            {/* iframe for sportbook */}
            {openSportBook &&
                (sportBooksignup?.src ?
                    <div className="overlaySportBook">
                        <div className="header">
                            <div className="navContainer">
                                <span className="navH1" style={{ color: "#333" }}>
                                </span>
                                <span className="navH1 text-capital" >
                                    {sportBooksignup?.sb}
                                </span>
                                <span className="navH1" >
                                    <img src={back} onClick={(e) => { setIsLoadingIFrame(true); setSportBookSignup({}) }} width="24px" />
                                </span>
                            </div>
                        </div>
                        {isLoadingIFrame && <img
                            src={loaderGIF}
                            style={{ margin: "18rem auto", display: "block" }}
                        />}
                        <iframe style={{ marginTop: "3.8rem" }} onLoad={() => setIsLoadingIFrame(false)} className="sportBookIframe" src={sportBooksignup?.src} title="sportBooksignup" width={"100%"} height={"100%"}></iframe>
                    </div>
                    :
                    <div className={`overlay2 ${openSportBook ? "openup" : ""}`} style={{ height: "100%", display: "block", overflowY: "auto", opacity: "0.95" }} >
                        {
                            <SportBookMenu key={"sportbook"} sportBookBet={sportBookBet} changeBetSportBook={changeBetSportBook} betIndex={betIndex} setSportBookSignup={setSportBookSignupPage} game={game}
                                showOnboardingNotifications={props?.showOnboardingNotifications} setShowOnboardingNotifications={props?.setShowOnboardingNotifications}
                                setOpenSportBook={setOpenSportBook} showConfidenceInfo={showConfidenceInfo} sportBooks={sportBooks} sportBookGame={sportBookGame} sportBookSection={sportBookSection} betType={betType} />
                        }
                    </div>)
            }
            {showToolTip && <div className={`overlay2 ${openSportBook ? "openup" : ""}`} style={{ height: "100%", display: "block", overflowY: "auto", background: "white", opacity: "1" }} >

                <ConfidenceInfo sportBookBet={sportBookBet} showConfidenceInfo={showConfidenceInfo} setOpenSportBook={setOpenSportBook} setShowToolTip={setShowToolTip} />
            </div>
            }

        </>
    );
}

