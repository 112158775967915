import { Route, Redirect } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { shallow } from 'zustand/shallow';
import { useStore } from "../store";

export default function TempRoute({ component: Component, ...rest }) {
  const { tempUser, setUpdateStore } = useStore(store => ({
    tempUser: store?.tempUser,
    setUpdateStore: store?.setUpdateStore
  }), shallow);
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(true);


  useEffect(() => {
    if (tempUser?.userId) {
      setStatus(tempUser?.userId);
    }
    setLoader(false)
    setUpdateStore()
  }, []);
  return (
    <>
      {!loader && (
        <Route
          {...rest}
          render={(props) =>
            tempUser?.userId ? <Component {...props} /> : <Redirect to="/" />
          }
        />
      )}
    </>
  )

}
