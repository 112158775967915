import React, { useState, useEffect } from "react";
import cancel from "../../img/cancelG.png";
import { Player, BigPlayButton, LoadingSpinner, PlayToggle } from 'video-react';


export default function VideoPopup({ url, setOpen }) {

    useEffect(async () => {
        console.log(url);
    }, [])

    return (
        true && <>
            <div >
                <div style={{ height: "60px" }}></div>
                <div className="container  dashboard " >
                    <header className="header">
                        <div className="navContainer">
                            <span className="navH1" style={{ color: "white" }}>
                                a
                            </span>
                            <span className="navH1 text-capital" style={{ color: "white" }}>
                                a
                            </span>

                            <span className="navH1" style={{ color: "#333" }}>
                                <img
                                    src={cancel}
                                    style={{ height: "22px" }}
                                    onClick={(e) => setOpen(false)}
                                />
                            </span>
                        </div>
                    </header>
                    <div style={{ marginTop: "5%" }}>
                        <div className="container p-0 " >
                            <div className="container text-center">
                                <Player autoPlay>
                                    <source fluid={100} src={url} autoPlay={true} />
                                    <LoadingSpinner />
                                    <BigPlayButton position="center" />
                                </Player>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

        </>
    );
}
