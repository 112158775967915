// import _ from 'lodash';
const axios = require("axios");

export const _getPrivacy = async (userId) => {
    try {
        const requestURL = "https://bettortakes-app.s3.us-east-2.amazonaws.com/legal/Privacy_Statement.html"
        var response = await axios.get(requestURL);
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}

export const _getTerms = async (userId) => {
    try {
        const requestURL = "https://bettortakes-app.s3.us-east-2.amazonaws.com/legal/Terms_Of_Service.html"
        var response = await axios.get(requestURL);
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}
