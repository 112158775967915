import { serverURL } from "../constants";
import _ from 'lodash';
import localForage from "localforage";
const axios = require("axios");

export const _addLogs = async (sender,data,event="no markets") => {
    try {
        const requestURL = serverURL + "log"
        let body = _({
            event,
            sender,
            data,
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        let response = await axios.post(requestURL, body)
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}