import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _getMessages = async (name) => {
    const requestURL = serverURL + "messages"
    let params = _({
        name,
    }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    var response = await axios.get(requestURL, { params });
    if(response?.data?.length>0)
        return {success:true,data:response.data};
    else
        return {success:false};
}