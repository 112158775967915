import React from 'react';
import bet from "../../img/bet.png";
import action from "../../img/actionB.png"
import insight from "../../img/insight.png";
import circle from "../../img/circleB.png";
import more from "../../img/more.png";

const BottomMenu = (props) => {
  return (
    <div className="bottom-menu">
      <div className="bottom-menu-item" onClick={(e) => { props.history.push("/") }}>
        <img src={bet} width={25} />
        <span >Bet</span>
      </div>
      <div className="bottom-menu-item" onClick={(e) => { props.history.push("/my-action") }}>
        <img src={action} width={25} />
        <span>Action</span>
      </div>
      <div className="bottom-menu-item">
        <img src={insight} width={25} onClick={(e) => { props.history.push("/my-stats") }} />
        <span>Insights</span>
      </div>
      <div className="bottom-menu-item" onClick={(e) => { props.history.push("/circle") }} >
        <img src={circle} width={25} />
        <span>Circles</span>
      </div>
      <div className="bottom-menu-item" style={{minWidth:"25px"}} >
        {/* <img src={more} width={25} />
        <span>More</span> */}
      </div>
    </div>
  );
};

export default BottomMenu;