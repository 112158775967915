import { createWithEqualityFn } from 'zustand/traditional';
import { devtools, persist } from "zustand/middleware"
import localForage from 'localforage';


const localForageAdapter = {
    getItem: async (key) => {
        const value = await localForage.getItem(key);
        return value ? JSON.parse(value) : undefined;
    },
    setItem: async (key, value) => {
        await localForage.setItem(key, JSON.stringify(value));
    },
    removeItem: async (key) => {
        await localForage.removeItem(key);
    }
};

const states = {
    betCount: 0,
    token: null,
    userData: {},
    userPlan: {},
    subscriptionPlans: [],
    showSharpBookDrawer: false,
    sharpsportsId: null,
    refreshSharpBook: false,
    reAuthenticateSharpBook: false,
    sharpSportBet: null,
    updateStore: false,
    betAnalysis: [],
    showOnboardingNotifications : "",
    tempUser:{},
    sportBooks:[],
    currentCircle:{}
}

const store = (set, get) => ({
    ...states,
    setBetCount: (props) => set((state) => ({ ...state, betCount: props }), false, "SetBetCount"),
    setToken: (props) => set((state) => ({ ...state, token: props }), false, "SetToken"),
    setUserData: (props) => set((state) => ({ ...state, userData: { ...get().userData, ...props } }), false, "setUserData"),
    setUserPlan: (props) => set((state) => ({ ...state, userPlan: props }), false, "setUserPlan"),
    setSubscriptionPlans: (props) => set((state) => ({ ...state, subscriptionPlans: props }), false, "setSubscriptionPlans"),
    setShowSharpBookDrawer: () => set((state) => ({ ...state, showSharpBookDrawer: !get().showSharpBookDrawer }), false, "setSharpBookDrawer"),
    setSharpsportId: (props) => set((state) => ({ ...state, sharpsportsId: props }), false, "setSharpsportId"),
    setRefreshSharpBook: (props) => set((state) => ({ ...state, refreshSharpBook: props }), false, "setRefreshSharpBook"),
    setReAuthenticateSharpBook: (props) => set((state) => ({ ...state, reAuthenticateSharpBook: props }), false, "setReAuthenticateSharpBook"),
    setSharpSportBet: (props) => set((state) => ({ ...state, sharpSportBet: props }), false, "setSharpSportBet"),
    setBetAnalysis: (props) => set((state) => ({ ...state, betAnalysis: props }), false, "setBetAnalysis"),
    setShowOnboardingNotifications: (props) => set((state) => ({ ...state, showOnboardingNotifications: props }), false, "setShowOnboardingNotifications"),
    setTempUser: (props) => set((state) => ({ ...state, tempUser: props }), false, "setTempUser"),
    setSportBooks: (props) => set((state) => ({ ...state, sportBooks: props }), false, "setSportBooks"),
    setCurrentCircle: (props) => set((state) => ({ ...state, currentCircle: props }), false, "setCurrentCircle"),
    setUpdateStore: () => set((state) => ({ ...state, updateStore: !get().updateStore }), false, "setUpdateStore"),
    logoutStore: () => set(() => ({ ...states }), false, "logout"),
})

export const useStore = createWithEqualityFn(persist(devtools(store), {
    name: "store",
    getStorage: () => localForageAdapter
}));