import { serverURL } from "../constants";
// import _ from 'lodash';
const axios = require("axios");

export const _getStates = async (userId) => {
    try {
        const requestURL = serverURL + "states"
        var response = await axios.get(requestURL);
        response = response.data;
        return { data: response, success: true }

    } catch (error) {
        return { success: false }
    }
}