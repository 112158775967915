import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-TJLGB8X'
}
if (window.location.href === 'https://m.bettortakes.com/')
  TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
reportWebVitals();
