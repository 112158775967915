import React, { createContext } from "react";
import AWS from 'aws-sdk';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute, AmazonCognitoIdentity } from "amazon-cognito-identity-js";
import { UserPool } from "./";

const AccountContext = createContext();

const Account = (prop) => {
  const getUserStatus = async () => {
    await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, data) => {
          if (err)
            reject(false)
          else {
            resolve(data)
          }
        })
      } else
        reject(false)
    })
  }

  const ChangePassword = (data) => {
    return new Promise((resolve, reject) => {
      const currentUser = UserPool.getCurrentUser()
      if (currentUser) {
        currentUser.getSession(function (err, session) {
          if (err) {
            resolve({ success: false, message: err.message });
          } else {
            currentUser.changePassword(data.old_password, data.password, function (err, res) {
              if (err) {
                resolve({ success: false, message: err.message });
              } else {
                resolve({ success: true, data: res });
              }
            });
          }
        });
      } else {
        resolve({ success: false, message: "User is not authenticated" });
      }
    });
  };

  const UpdateUserEmail = (email) => {
    let attributeList = [];
    const attribute = new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    });
    attributeList.push(attribute);
    return new Promise((resolve, reject) => {
      const currentUser = UserPool.getCurrentUser()
      if (currentUser) {
        currentUser.getSession(function (err, session) {
          if (err) {
            resolve({ success: false, message: err.message });
          } else {
            currentUser.updateAttributes(attributeList, function (err, res) {
              if (err) {
                resolve({ success: false, message: err.message });
              } else {
                resolve({ success: true, data: res });
                // resolve(res);
              }
            });
          }
        });
      } else {
        resolve({ success: false, message: "User is not authenticated" });
      }
    });

  }

  const authenticateUser = async (Username, Password) => {
    await new Promise((resolve, reject) => {
      // create A user
      const user = new CognitoUser({ Username, Pool: UserPool });
      // auth details
      const authDetail = new AuthenticationDetails({ Username, Password });
      // authenticate user
      user.authenticateUser(authDetail, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    if (user)
      user.signOut();
  }

  const deleteUser = () => {
    const currentUser = UserPool.getCurrentUser()
    if (currentUser) {
      currentUser.getSession(function (err, session) {
        if (err) {
          return false;
        } else {
          currentUser.deleteUser(function (err, result) {
            if (err) {
              return false;
            }
            return true;
          });
        }
      });
    }
    else
      return false;

  }

  const resentOTP = (email) => {
    var cognitoUser = new CognitoUser({
      Username: email.toLowerCase(),
      Pool: UserPool,
    });

    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }

    });
  };

  const getCognito = ()=>{
    return  new AWS.CognitoIdentityServiceProvider({
      region: process.env.REACT_APP_REGION
    });
  }
  const findUserByEmail = async (email) => {
    const params = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Replace with your user pool ID
      Filter: `email = "${email}"`,
      Limit: 1,
    };
  
    try {
      const result = await getCognito().listUsers(params).promise();
      if (result.Users && result.Users.length > 0) {
        return { success: true, username: result.Users[0].Username };
      } else {
        return { success: false, message: 'User not found' };
      }
    } catch (error) {
      return { success: false, message: error.message };
    }
  };
  
  const deleteUserByUsername = async (username) => {
    const params = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID, // Replace with your user pool ID
      Username: username,
    };
  
    try {
      await getCognito().adminDeleteUser(params).promise();
      return { success: true, message: 'User deleted successfully' };
    } catch (error) {
      return { success: false, message: error.message };
    }
  };
  
  const deleteUserByEmail = async (email) => {
    const findUserResult = await findUserByEmail(email);
    if (findUserResult.success) {
      const deleteResult = await deleteUserByUsername(findUserResult.username);
      return deleteResult;
    } else {
      return findUserResult;
    }
  };
  return (
    <AccountContext.Provider value={{ authenticateUser, getUserStatus, logout, ChangePassword, UpdateUserEmail, deleteUser, resentOTP,deleteUserByEmail }}>
      {prop.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
