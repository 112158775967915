import React, { useState, useEffect } from "react";
import { _getMessages } from "../../../services";
import cancel from "../../../img/cancelG.png";
import play from "../../../img/play.png"
import VideoPopup from "../videoPopup";
import ReactHtmlParser from 'react-html-parser';

export default function ConfidenceInfo({showConfidenceInfo}) {
    const [render, setRender] = useState(false);
    const [contestName, setContestName] = useState({});
    const [contest, setMsg] = useState({});
    const { innerWidth, innerHeight } = window;
    const [open, setOpen] = useState(false);

    useEffect(async () => {
        let msg;
        let name = `ConfidenceTooltip`;
        if(localStorage.getItem(name)){
            msg = JSON.parse(localStorage.getItem(name));
            _getMessages(name).then((res) => {
                if(msg.success && msg.data){
                    localStorage.setItem(name, JSON.stringify(msg));
                }
            });
        }
        else{
            msg = await _getMessages(name)
            localStorage.setItem(name, JSON.stringify(msg));
        }
        if (msg.success && msg.data) {
            if (msg?.data?.[0]?.message?.includes("<OL>") || msg?.data?.[0]?.message?.includes("<ol>")) {
                msg.data[0].message = msg.data[0].message.replaceAll("<OL>", "<ol>");
                msg.data[0].message = msg.data[0].message.replaceAll("<ol>", "<ol class='notificationOl'>");
            }
            if (msg?.data?.[0]?.message?.includes("<LI>") || msg?.data?.[0]?.message?.includes("<li>")) {
                msg.data[0].message = msg.data[0].message.replaceAll("<LI>", "<li>");
                msg.data[0].message = msg.data[0].message.replaceAll("<li>", "<li class='notificationli'>");
            }
            if (msg?.data?.[0]?.message?.includes("<\\BR>") || msg?.data?.[0]?.message?.includes("<BR>")) {
                msg.data[0].message = msg.data[0].message.replaceAll("<\\BR>", "<br>");
            }
            setMsg({
                ...msg.data[0],
                // "vLinkText": "Watch video",
                // "vLink": "https://bettortakes-app.s3.us-east-2.amazonaws.com/videos/Untitled.mp4",
            });
            setContestName(msg.data[0].heading)
        }
        setRender(true)

    }, [])

    const back = (e) => {
        showConfidenceInfo(e,false);
    }


    return (
        render &&
        (open ? <VideoPopup url={contest?.vLink} setOpen={setOpen} /> :
            <>
                <div >
                    <div style={{ height: "60px" }}></div>
                    <div className="container  dashboard " >
                        <header className="header">
                            <div className="navContainer">
                                <span className="navH1" style={{ color: "white" }}>
                                    a
                                </span>
                                <span className="navH1 text-capital" >
                                    {contestName}
                                </span>

                                <span className="navH1" style={{ color: "#333" }}>
                                    <img
                                        src={cancel}
                                        style={{ height: "22px" }}
                                        onClick={(e) => back(e)}
                                    />
                                </span>
                            </div>
                        </header>

                        <div style={{ marginTop: "11%" }}>
                            <div className="container p-0 " >
                                <div className="notificationBody" style={{ fontFamily: "Quicksand", fontSize: "16px", marginTop: "12px", marginBottom: "0.5rem", textAlign: "left", padding: "0 5px 0 21px", height: contest.vLinkText ? "54vh" : "63vh", overflowY: "auto" }}>
                                    <span>
                                        {ReactHtmlParser(contest?.message)}
                                    </span>
                                </div>
                                {contest?.vLinkText && contest?.vLink &&
                                    <div className="container text-center" style={{ marginTop: "10px" }} onClick={e => setOpen(true)}>
                                        <img src={play} width="20px" style={{ marginLeft: "7px" }} />
                                        <span className="notificationVideo">{contest?.vLinkText || "Show me how it works"}</span>
                                    </div>
                                }
                            </div>


                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="df dfr p-0" style={{ lineHeight: "20px", fontFamily: "IBM Plex Sans", height: "52px", position: "fixed", zIndex: 1, width: "92%", bottom: "5%" }} >
                        <button className="applyFilter" style={{ fontWeight: "500", marginLeft: "5px", backgroundColor: "#222222" }} onClick={(e) => back(e)}>
                            {/* {contest?.type ? "GOT IT" : "CLOSE"} */}
                            {contest?.button1}
                        </button>

                    </div>
                </div>
            </>)
    );
}
