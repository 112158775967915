import React, { useEffect, useContext, useState } from "react";
import { AccountContext } from "../cognito";
import localForage from "localforage";
import { useIntercom } from 'react-use-intercom';
import account from "../../img/account.png"
import coins from "../../img/coinsW.png";
import { isValidToken } from "../../util"
import BottomMenu from "./bottomNav";
import { useStore } from "../../store";
import { shallow } from "zustand/shallow"
import { ON_BOARDING } from "../../constants";

const crypto = require('crypto');

const Sidebar = (props) => {
  const { shutdown, boot } = useIntercom();

  const { userData, betCount, token, setToken, logoutStore } = useStore((state) => ({
    betCount: state?.betCount,
    token: state?.token,
    userData: state?.userData,
    setToken: state.setToken,
    logoutStore: state?.logoutStore
  }), shallow)
  const { logout } = useContext(AccountContext);
  const [loader, setLoader] = useState(true);
  const [email, setEmail] = useState("");
  function signature(query_string) {
    return crypto.createHmac('sha256', "7ecWAov1nlFkgzM6fzZUS46KpaI3XlqLfEpjNNyw").update(query_string).digest('hex');
  }
  const { show } = useIntercom();
  useEffect(async () => {
    isValidToken({ token, history: props.history, signout, setToken, userData })
    if (userData != null) {
      const user_hash = { "user_hash": signature({ email: userData?.userId }) }
      boot({ user_hash });
    }

    const email = await localForage.getItem("email") || null;
    setEmail(email);
  }, []);

  const ToLogin = (e, history) => {
    e.preventDefault();
    history.push("/sign-in");
  };

  const signout = () => {
    localStorage.removeItem("navTab");
    localStorage.removeItem("headerTabs");
    localStorage.removeItem("currentCircle");
    localStorage.removeItem("lastLeague");
    localStorage.removeItem("userSetting");
    localStorage.removeItem("betDay");
    localStorage.removeItem("prevPath");
    localStorage.removeItem("grid");
    localStorage.removeItem("sharpbets");
    localStorage.removeItem("token");
    localStorage.removeItem("insights");
    localStorage.removeItem("betMonth");
    localStorage.removeItem("betYear");
    localStorage.removeItem("prevPath");
    localStorage.removeItem("gameId");
    localStorage.removeItem("notification");
    localStorage.removeItem("userPlan");
    localStorage.removeItem("ConfidenceTooltip");
    localStorage.removeItem("states");

    localForage.clear();
    shutdown();
    logout();
    logoutStore();
    props.history.push("/sign-in");
  };

  return (
    <>
      {
        props.showSideMenu ? <nav>
          {loader && (
            <ul
              className="mainNav m-0"
              style={props.sideBar ? { transform: "translateX(0)" } : null}
            >
              {!email && (
                <>
                  <button
                    style={{ lineHeight: "1.7" }}
                    onClick={(e) => ToLogin(e, props.history)}
                    className="btn btn-lg  btn-block btn-login text-uppercase font-weight-bold mb-4 mt-4"
                  >
                    sign in
                  </button>
                  <hr />
                </>
              )}
              {email && (
                <>
                  <li style={{ listStyle: "none" }} >
                    <button className="pickgame" onClick={(e) => { props.handleSidebar(e); props.history.push("/betSync") }}>
                      SPORTSBOOK SYNC
                    </button>
                    <hr />
                  </li>
                  {/* <li className="mt-2" style={{ listStyle: "none" }}>
                    <a className="mainNavLink" onClick={(e) => { props.handleSidebar(e); props.history.push("/my-stats") }}>
                      <img src={state} width="20px" />
                      <span style={{ marginLeft: "10px", color: "#ffffff" }}>
                        My Stats
                      </span>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="mainNavLink"
                      onClick={(e) => { props.handleSidebar(e); props.history.push("/my-action") }}
                    >
                      <img src={action} width="20px" style={{ marginTop: "-2px" }} />
                      <span style={{ marginLeft: "10px", color: "#ffffff" }}>
                        My Action
                      </span>

                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="mainNavLink"
                      onClick={(e) => { props.handleSidebar(e); props.history.push("/circle") }}
                    >
                      <img src={circle} width="20px" style={{ marginTop: "-2px" }} />
                      <span style={{ marginLeft: "10px", color: "#ffffff" }}>
                        My Circles
                      </span>
                    </a>
                  </li> */}
                  <li style={{ listStyle: "none" }}>
                    <a className="mainNavLink"
                      onClick={(e) => { props.handleSidebar(e); props.history.push("/accountInfo") }}
                    >
                      <img src={account} width="20px" style={{ marginTop: "-2px" }} />
                      <span style={{ marginLeft: "10px", color: "#ffffff" }}>
                        My Account
                      </span>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      className="mainNavLink"
                      onClick={(e) => { props.handleSidebar(e); props.history.push("/leaguePreferences") }}
                    >
                      <img src={coins} width="20px" style={{ marginTop: "-2px" }} />
                      <span style={{ marginLeft: "10px", color: "#ffffff" }}>
                      Betting Preferences
                      </span>
                    </a>
                  </li>
                  <hr />
                </>
              )}
              <li style={{ listStyle: "none" }} onClick={(e) => { props.handleSidebar(e); show() }}>
                <a className="mainNavLink" href="#">
                  Help
                </a>
              </li>
              <li style={{ listStyle: "none" }}>
                <a className="mainNavLink"
                  onClick={(e) => { props.handleSidebar(e); props.history.push("/policy") }}
                >
                  Privacy & Terms
                </a>
              </li>
              <hr />
              {email && (
                <>
                  <li style={{ listStyle: "none" }}>
                    <a className="mainNavLink" href="#" onClick={signout}>
                      Log Out
                    </a>
                  </li>
                  <a className="mainNavLink mt-4" href="#" onClick={(e) => { props.handleSidebar(e); show() }}>
                    Suggestions and feedback?
                  </a>
                </>
              )}
              <div className="footer">
                <div className="container">
                  <div className="container space-between-horizontal">
                    <a
                      href="https://twitter.com/BettorTakes"
                      target="blank"
                      className="fa fa-twitter"
                    ></a>
                    <a
                      href="https://www.instagram.com/bettortakes/"
                      target="blank"
                      className="fa fa-instagram"
                    ></a>
                    <a
                      href="https://www.youtube.com/channel/UCZJAqkde2WzVH4awkTttlZ"
                      target="blank"
                      className="fa fa-youtube"
                    ></a>
                    <a
                      href="https://www.facebook.com/BettorTakes"
                      target="blank"
                      className="fa fa-facebook"
                    ></a>
                  </div>
                  <div className="copyright mt-5 text-center">
                    © Betflair LLC - all rights reserved
                  </div>
                </div>
              </div>
            </ul>
          )}
        </nav> :
          (!(window?.location?.pathname == "/" && betCount > 0) && !ON_BOARDING?.includes(props?.showOnboardingNotifications)) && <BottomMenu email={props.email}
            sideBar={props.sideBar}
            setShowSideMenu={props.setShowSideMenu}
            handleSidebar={props.handleSidebar}
            setSitebar={props.setSitebar}
            history={props.history} />}
    </>
  );
};

export default Sidebar;
