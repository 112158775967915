import React, { useEffect, useContext, useState } from "react";
import loaderGIF from "../../../img/newLoader.gif";
import { formatAMPM, getStringDate } from "../helper"
import { ON_BOARDING } from "../../../constants";
const GameTile = ({show,currentLeague,showOnboardingNotifications ,...props}) => {
  const [loader, setLoader] = useState(false);

  const [game, setGame] = useState({});
  const [isLive, setLive] = useState(false);

  useEffect(() => {
    setLoader(true);
    let cGame = Object.assign({ selected: false }, props);
    cGame.time = formatAMPM(new Date(cGame.gameTime));
    cGame.day = getStringDate(new Date(cGame.gameTime));
    setLive(parseInt(cGame.livePeriod) > 0);
    setLoader(false);
    setGame(cGame);
  }, []);



  return (
    <>
      {!loader ? (
        <>
        <div className={`${showOnboardingNotifications==ON_BOARDING[3] ? 'blur' : ''}`}>
        </div>

        <div className={`container p-b-5`}>
          <>
            {!props.sportBookGame && <div className="text-center text fontGray fs-11 pt-3 pb-1">
              {isLive && !(game.status.toLowerCase() == "final" || game.status.toLowerCase() == "full_time") ? (
                <span>
                  <span className="dot"> </span>LIVE
                </span>
              ) : (
                <>
                  {game.day}, {game.time}
                </>
              )}
            </div>
            }
            {/* second row */}
            <div className="row m-0">
              {/* left side */}
              <div className="col4 text-right">
                <div className="df fdc mt-2">
                  {/* upper */}
                  <span className="text fontGray fs-10 text-capital">
                    {game.awayTeam?.team}
                  </span>
                  {/* lower */}
                  <span className="text fs-20" style={{ lineHeight: 1 }}>
                    {game.awayTeam?.mascot?.substring(0, 9)}
                  </span>
                </div>
              </div>
              {/* center */}
              <div className="col4">
                <div className="row m-0">
                  <div className="col3 p-0">
                    <img
                      src={game.awayTeam?.logo}
                      loading="lazy"
                      className="mt-1"
                      width="42px"
                      style={{ maxHeight: "46px" }}
                    />
                  </div>
                  <div
                    className="col6 p-0 text-center"
                    style={{ height: "45px", lineHeight: "45px" }}
                  >
                    <span className="fs-10 fontGray">{currentLeague?.sport == "MMA" ? "VS" :"@"}</span>
                  </div>
                  <div className="col3 pl-0">
                    <img
                      src={game.homeTeam?.logo}
                      loading="lazy"
                      className="mt-1"
                      width="42px"
                      style={{ maxHeight: "46px" }}
                    />
                  </div>
                </div>
              </div>
              {/* right side */}
              <div className="col4 p-0">
                <div className="df fdc mt-2">
                  <span className="text fontGray fs-10 text-capital">
                    {game.homeTeam?.team}
                  </span>
                  <span>
                    <span className="text fs-20" style={{ lineHeight: 1 }}>
                      {game.homeTeam?.mascot?.length > 9 ? (
                        <>
                          {game.homeTeam?.mascot?.substring(0, 8)}
                          {".."}
                        </>
                      ) : (
                        <>{game.homeTeam?.mascot}</>
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {/* 3rd row */}
            {!props.sportBookGame && show &&<>
              {/* if not live */}
              {game.status?.toLowerCase() == "scheduled" && (
                <>
                  <div className="row m-0">
                    <div className="col4 text-right">
                      <span
                        className="text fs-11"
                        style={{ lineHeight: 1, }}
                      >
                        {game.bestOdds?.[0].betline > 0 ? "+ " + game.bestOdds?.[0].betline : game.bestOdds?.[0].betline} &nbsp;

                      </span>

                      <span className="text fontGray fs-11 text-capital">ML</span>
                      <span className="text fs-11 text-capital">
                        {game.bestOdds?.[2]?.moneyline > 0 ? "+ " + game.bestOdds?.[2]?.moneyline : game.bestOdds?.[2]?.moneyline}
                      </span>
                    </div>
                    <div className="col4 text-center">
                      <span className="text fontGray fs-11 text-capital">
                        Total
                      </span>
                      <span className="text fs-11 text-capital">
                        {" "}
                        {
                          game.bestOdds?.[5]?.betline
                        }
                      </span>
                    </div>
                    <div className="col4 ">


                      <span className="text fontGray fs-11 text-capital">ML</span>
                      <span className="text fs-11 text-capital">
                        {game.bestOdds?.[3]?.moneyline > 0 ? "+ " + game.bestOdds?.[3]?.moneyline : game.bestOdds?.[3]?.moneyline}
                      </span>
                      <span
                        className="text fs-11 "
                        style={{ lineHeight: 1, textDecoration: "bold" }}
                      >
                        &nbsp; {game.bestOdds?.[1]?.betline > 0 ? "+ " + game.bestOdds?.[1]?.betline : game.bestOdds?.[1]?.betline}
                      </span>
                    </div>
                  </div>
                </>
              )}
              {(game.status?.toLowerCase() == "final" || game.status?.toLowerCase() == "full_time") && (
                <>
                  <div className="row m-0">
                    <div className="col4 text-right">
                      <span className="text fs-20 text-capital">
                        {" "}&nbsp;&nbsp;{game.awayScore}
                      </span>
                    </div>
                    <div className="col4 text-center" style={{ alignSelf: "flex-end" }}>
                      <span className="text fontGray fs-11 text-capital" >
                        FINAL
                      </span>
                    </div>
                    <div className="col4 ">
                      <span className="text fs-20 text-capital">
                        {game.homeScore}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
            }
            {isLive && show && !(game.status.toLowerCase() == "final" || game.status.toLowerCase() == "full_time") && (
              <>
                <div className="row m-0">
                  <div className="col4 text-right">
                    <span className="text fs-11 fontGray" >
                      {game.bestOdds?.[0].betline > 0 ? "+ " + game.bestOdds?.[0].betline : game.bestOdds?.[0].betline}{" "}
                    </span>

                    <span className="text fs-20 text-capital">
                      {" "}&nbsp;&nbsp;{game.awayScore}
                    </span>
                  </div>
                  <div className="col4 text-center" style={{ alignSelf: "flex-end" }}>
                    <span className="text fontGray fs-11 text-capital" >
                      {/* {game.liveClock} */}
                      {game.liveStatus}

                    </span>
                  </div>
                  <div className="col4 ">
                    <span className="text fs-20 text-capital">
                      {game.homeScore}
                    </span>
                    <span className="text fs-11 fontGray" style={{ lineHeight: 1, }}>
                      {" "}&nbsp;&nbsp;{game.bestOdds?.[1]?.betline > 0 ? "+ " + game.bestOdds?.[1]?.betline : game.bestOdds?.[1]?.betline}{" "}
                    </span>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
        </>
      ) : (
        <div style={{ textAlign: "center", marginTop: "40%" }}>
          <img
            src={loaderGIF}
            style={{ margin: "10px auto", display: "block" }}
          />
        </div>
      )}
    </>
  );
};
export { GameTile };
