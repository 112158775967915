import { BrowserView, MobileView } from "react-device-detect";
import { BrowserRouter } from "react-router-dom";
import { Account } from "./components/cognito";
import Routes from "./routes/routes";
import { IntercomProvider } from 'react-use-intercom';
import useHydration from './store/useHyderation';
import "./css/index.css";
export default () => {
  const hydrated = useHydration();
  if (process.env.REACT_APP_LOCAL != "true") {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
  }
  const INTERCOM_APP_ID = "kah0dfg4"
  return (

    <IntercomProvider appId={INTERCOM_APP_ID}>
      <BrowserView style={{ position: "relative" }}>
        <div className="container text-center">
          <h4 style={{ marginTop: "30%" }}>
            BettorTakes is designed for mobile. Desktop experience coming soon.
          </h4>
        </div>
      </BrowserView>
      {hydrated ?
        <MobileView>
          <BrowserRouter>
            <Account className="App">
              <Routes />
            </Account>
          </BrowserRouter>
        </MobileView>
        : <div>Loading</div>}
    </IntercomProvider>
  );
};
