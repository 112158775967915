import { serverURL,APIKEY } from "../constants";
import _ from 'lodash';
const axios = require("axios");

export const _getAccessToken = async (userId) => {
    const requestURL = serverURL + "auth"
    let body = {userId,key:APIKEY}
    let response = await axios.post(requestURL, body)
    return {success:true,data:response.data};
}

export const _updatePassword = async (token,password) => {
    const requestURL = serverURL + "auth/updatepassword";
    const headers = {Authorization: token}
    try {
        let body = _({
            password
        }).omitBy(_.isUndefined).value();
        var response = await axios.put(requestURL, body,{headers});
        if (response.data)
            return { success: true, data: response.data };
        else
            return { success: false };
    } catch (error) {
        return { success: false };
    }
}