import { serverURL } from "../constants";
import _ from 'lodash';
const axios = require("axios");


export const _userEmailChanged = async (userId, newUser) => {
    const requestURL = serverURL + "bets/newowner"
    let body = {
        userId,
        newUser
    }
    let response = await axios.put(requestURL, body)
    response = response?.data
    return response;
}

export const _userSetting = async (userId, bodyData) => {
    const requestURL = serverURL + "userSettings";
    let body = _({ userId, ...bodyData }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    let response = await axios.post(requestURL, body);
    response = response?.data?.newUserSettings ?? null
    return response;
}

export const _updateUser = async (userId, bodyData) => {
    const requestURL = serverURL + "userSettings"
    let body = _({ userId, ...bodyData }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
    if(bodyData.betSyncAuto!=undefined)
        body.betSyncAuto=bodyData?.betSyncAuto;
    let response = await axios.put(requestURL, body)
    response = response?.data ?? null
    return response;
}

export const _getUserSetting = async (userId) => {
    try {
        const requestURL = serverURL + "usersettings"
        let params = _({
            userId
        }).omitBy(_.isNaN).omitBy(_.isUndefined).omitBy(_.isEmpty).value();
        var response = await axios.get(requestURL, { params });
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _getUserGravatar = async (userId) => {
    try {
        const requestURL = `https://www.gravatar.com/avatar/${userId}?size=64&d=404`
        var response = await axios.get(requestURL);
        if (response?.data)
            return { data: response.data, success: true }
        else
            return { success: false }

    } catch (error) {
        return { success: false }
    }
}

export const _updateUserSetting = async (userId, bodyData) => {
    const headers = { Authorization: localStorage.getItem("token") }

    try {
        const requestURL = serverURL + "auth/usersettings"
        let body = _({
            ...bodyData,
            userId
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        if (bodyData?.bettingUnit != null && bodyData?.bettingUnit >= 0)
            body.bettingUnit = bodyData?.bettingUnit;
        var response = await axios.put(requestURL, body, { headers });
        response = response.data;
        return response;
    } catch (error) {
        console.log("in catch");
        console.log(error);
        return null;
    }

}

export const _deleteUser = async (userId) => {
    const headers = { Authorization: localStorage.getItem("token") }
    try {
        const requestURL = serverURL + "auth/usersettings"
        let body = _({
            userId
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        var response = await axios.delete(requestURL, { headers, data: body });
        response = response.data;
        return response;
    } catch (error) {
        console.log("in catch");
        return null;
    }

}

export const _getUserName = async (screenName) => {
    const headers = { Authorization: localStorage.getItem("token") }

    try {
        const requestURL = serverURL + "auth/usersettings"
        let params = _({
            screenName
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        var response = await axios.get(requestURL, { params, headers });
        response = response.data;
        return response;
    } catch (error) {
        console.log("in catch");
        return null;
    }
}

export const _getUserByScreenName = async (screenName) => {
    try {
        const requestURL = serverURL + "usersettings/screenname"
        let params = _({
            screenName
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        var response = await axios.get(requestURL, { params });
        response = response.data;
        return response;
    } catch (error) {
        return null;
    }
}

export const _getUserByPhoneNumber = async (mobilePhone) => {
    try {
        const requestURL = serverURL + "usersettings/mobile"
        let params = _({
            mobilePhone
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        var response = await axios.get(requestURL, { params });
        response = response.data;
        return response;
    } catch (error) {
        return null;
    }
}

export const _getUserById = async (userId) => {
    const headers = { Authorization: localStorage.getItem("token") }

    try {
        const requestURL = serverURL + "auth/usersettings"
        let params = _({
            userId
        }).omitBy(_.isNaN).omitBy(_.isNull).omitBy(_.isUndefined).value();
        var response = await axios.get(requestURL, { params, headers });
        response = response.data;
        return response;
    } catch (error) {
        console.log("in catch");
        return null;
    }
}